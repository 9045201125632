import React, { useState, useEffect } from 'react';
import {
  Settings, Users, Shield, Mail, CreditCard, Wrench,
  Palette, Database, Search, Info, Save, X, AlertTriangle,
  CheckCircle2, RefreshCw, Download, Upload, Lock
} from 'lucide-react';
import { supabase } from '../lib/supabase';

interface Setting {
  id: string;
  category: string;
  key: string;
  value: string;
  description: string;
  type: 'text' | 'number' | 'boolean' | 'select' | 'color' | 'email';
  options?: string[];
}

const AdminSettings = () => {
  const [activeSection, setActiveSection] = useState('general');
  const [settings, setSettings] = useState<Setting[]>([]);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [showBackupModal, setShowBackupModal] = useState(false);

  const sections = [
    { id: 'general', name: 'General Platform Settings', icon: Settings },
    { id: 'users', name: 'User Management & Permissions', icon: Users },
    { id: 'security', name: 'Security & Authentication', icon: Shield },
    { id: 'email', name: 'Email Configuration', icon: Mail },
    { id: 'payment', name: 'Payment & Billing', icon: CreditCard },
    { id: 'integrations', name: 'Integrations & API', icon: Wrench },
    { id: 'branding', name: 'Customization & Branding', icon: Palette },
    { id: 'maintenance', name: 'System Maintenance', icon: Database }
  ];

  useEffect(() => {
    fetchSettings();
  }, []);

  const fetchSettings = async () => {
    try {
      setLoading(true);
      const { data, error } = await supabase
        .from('settings')
        .select('*')
        .order('category', { ascending: true });

      if (error) throw error;
      setSettings(data || []);
    } catch (error) {
      console.error('Error fetching settings:', error);
      setError('Failed to load settings');
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async (setting: Setting, newValue: string) => {
    try {
      setSaving(true);
      const { error } = await supabase
        .from('settings')
        .update({ value: newValue })
        .eq('id', setting.id);

      if (error) throw error;

      setSettings(settings.map(s => 
        s.id === setting.id ? { ...s, value: newValue } : s
      ));

      setSuccess('Settings saved successfully');
      setTimeout(() => setSuccess(null), 3000);
    } catch (error) {
      console.error('Error saving setting:', error);
      setError('Failed to save settings');
      setTimeout(() => setError(null), 3000);
    } finally {
      setSaving(false);
    }
  };

  const handleBackup = async () => {
    try {
      const { data, error } = await supabase
        .from('settings')
        .select('*');

      if (error) throw error;

      const blob = new Blob([JSON.stringify(data, null, 2)], { type: 'application/json' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `settings-backup-${new Date().toISOString()}.json`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);

      setSuccess('Backup created successfully');
      setTimeout(() => setSuccess(null), 3000);
    } catch (error) {
      console.error('Error creating backup:', error);
      setError('Failed to create backup');
      setTimeout(() => setError(null), 3000);
    }
  };

  const handleRestore = async (file: File) => {
    try {
      const reader = new FileReader();
      reader.onload = async (e) => {
        try {
          const settings = JSON.parse(e.target?.result as string);
          const { error } = await supabase
            .from('settings')
            .upsert(settings);

          if (error) throw error;

          await fetchSettings();
          setSuccess('Settings restored successfully');
          setTimeout(() => setSuccess(null), 3000);
        } catch (error) {
          console.error('Error restoring settings:', error);
          setError('Failed to restore settings');
          setTimeout(() => setError(null), 3000);
        }
      };
      reader.readAsText(file);
    } catch (error) {
      console.error('Error reading backup file:', error);
      setError('Failed to read backup file');
      setTimeout(() => setError(null), 3000);
    }
  };

  const filteredSettings = settings.filter(setting => 
    setting.category === activeSection &&
    (setting.key.toLowerCase().includes(searchQuery.toLowerCase()) ||
     setting.description.toLowerCase().includes(searchQuery.toLowerCase()))
  );

  return (
    <div className="min-h-screen bg-gray-50 pt-24">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col lg:flex-row gap-8">
          {/* Sidebar */}
          <div className="lg:w-64 flex-shrink-0">
            <div className="bg-white rounded-lg shadow overflow-hidden">
              <nav className="space-y-1">
                {sections.map(section => (
                  <button
                    key={section.id}
                    onClick={() => setActiveSection(section.id)}
                    className={`w-full flex items-center px-4 py-3 text-sm font-medium ${
                      activeSection === section.id
                        ? 'bg-[#B6AC7D] text-white'
                        : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900'
                    }`}
                  >
                    <section.icon className="h-5 w-5 mr-3" />
                    {section.name}
                  </button>
                ))}
              </nav>
            </div>

            {/* Backup/Restore */}
            <div className="mt-6 bg-white rounded-lg shadow p-4">
              <h3 className="text-sm font-medium text-gray-900 mb-4">Backup & Restore</h3>
              <div className="space-y-3">
                <button
                  onClick={handleBackup}
                  className="w-full flex items-center justify-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
                >
                  <Download className="h-4 w-4 mr-2" />
                  Export Settings
                </button>
                <label className="w-full flex items-center justify-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 cursor-pointer">
                  <Upload className="h-4 w-4 mr-2" />
                  Import Settings
                  <input
                    type="file"
                    className="hidden"
                    accept=".json"
                    onChange={(e) => {
                      const file = e.target.files?.[0];
                      if (file) handleRestore(file);
                    }}
                  />
                </label>
              </div>
            </div>
          </div>

          {/* Main Content */}
          <div className="flex-1">
            <div className="bg-white rounded-lg shadow">
              {/* Header */}
              <div className="px-6 py-4 border-b border-gray-200">
                <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4">
                  <h2 className="text-lg font-medium text-gray-900">
                    {sections.find(s => s.id === activeSection)?.name}
                  </h2>
                  <div className="relative">
                    <input
                      type="text"
                      placeholder="Search settings..."
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      className="w-full sm:w-64 pl-10 pr-4 py-2 rounded-lg border text-gray-900 placeholder-gray-500 focus:ring-2 focus:ring-[#B6AC7D] focus:border-transparent"
                    />
                    <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
                  </div>
                </div>
              </div>

              {/* Settings Grid */}
              <div className="p-6">
                {loading ? (
                  <div className="space-y-4">
                    {[1, 2, 3].map((n) => (
                      <div key={n} className="animate-pulse">
                        <div className="h-4 bg-gray-200 rounded w-1/4 mb-2"></div>
                        <div className="h-10 bg-gray-200 rounded"></div>
                      </div>
                    ))}
                  </div>
                ) : filteredSettings.length === 0 ? (
                  <div className="text-center py-12">
                    <Settings className="mx-auto h-12 w-12 text-gray-400" />
                    <h3 className="mt-2 text-sm font-medium text-gray-900">No settings found</h3>
                    <p className="mt-1 text-sm text-gray-500">
                      {searchQuery ? 'Try adjusting your search terms' : 'No settings available for this section'}
                    </p>
                  </div>
                ) : (
                  <div className="grid grid-cols-1 gap-6">
                    {filteredSettings.map((setting) => (
                      <div key={setting.id} className="space-y-2">
                        <div className="flex items-center justify-between">
                          <label className="block text-sm font-medium text-gray-700">
                            {setting.key}
                          </label>
                          <button
                            className="text-gray-400 hover:text-gray-500"
                            title="More information"
                          >
                            <Info className="h-4 w-4" />
                          </button>
                        </div>
                        <div className="flex gap-2">
                          {setting.type === 'boolean' ? (
                            <button
                              onClick={() => handleSave(setting, setting.value === 'true' ? 'false' : 'true')}
                              className={`relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-[#B6AC7D] focus:ring-offset-2 ${
                                setting.value === 'true' ? 'bg-[#B6AC7D]' : 'bg-gray-200'
                              }`}
                            >
                              <span
                                className={`pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out ${
                                  setting.value === 'true' ? 'translate-x-5' : 'translate-x-0'
                                }`}
                              />
                            </button>
                          ) : setting.type === 'select' ? (
                            <select
                              value={setting.value}
                              onChange={(e) => handleSave(setting, e.target.value)}
                              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-[#B6AC7D] focus:ring-[#B6AC7D] sm:text-sm"
                            >
                              {setting.options?.map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>
                          ) : (
                            <input
                              type={setting.type}
                              value={setting.value}
                              onChange={(e) => handleSave(setting, e.target.value)}
                              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-[#B6AC7D] focus:ring-[#B6AC7D] sm:text-sm"
                            />
                          )}
                        </div>
                        <p className="text-sm text-gray-500">{setting.description}</p>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* Notifications */}
        {(error || success) && (
          <div className={`fixed bottom-4 right-4 p-4 rounded-lg shadow-lg ${
            error ? 'bg-red-50 border border-red-200' : 'bg-green-50 border border-green-200'
          }`}>
            <div className="flex items-center">
              {error ? (
                <AlertTriangle className="h-5 w-5 text-red-400 mr-2" />
              ) : (
                <CheckCircle2 className="h-5 w-5 text-green-400 mr-2" />
              )}
              <p className={error ? 'text-red-800' : 'text-green-800'}>
                {error || success}
              </p>
              <button
                onClick={() => {
                  setError(null);
                  setSuccess(null);
                }}
                className="ml-4"
              >
                <X className="h-5 w-5 text-gray-400" />
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminSettings;