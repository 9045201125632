import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { supabase } from '../lib/supabase';
import { Bell, Bookmark, Settings, Heart, MessageSquare } from 'lucide-react';

const Account = () => {
  const { user, profile, refreshProfile } = useAuth();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState<'notifications' | 'saved' | 'settings'>('notifications');
  const [notifications, setNotifications] = useState([]);
  const [savedArticles, setSavedArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [profileForm, setProfileForm] = useState({
    username: '',
    bio: '',
    avatar_url: ''
  });

  useEffect(() => {
    if (!user) {
      navigate('/auth');
      return;
    }

    // Initialize form with current profile data
    if (profile) {
      setProfileForm({
        username: profile.username || '',
        bio: profile.bio || '',
        avatar_url: profile.avatar_url || ''
      });
    }

    fetchUserData();
  }, [user, activeTab, profile]);

  const fetchUserData = async () => {
    setLoading(true);
    try {
      if (activeTab === 'notifications') {
        const { data: notifications } = await supabase
          .from('notifications')
          .select('*')
          .eq('user_id', user?.id)
          .order('created_at', { ascending: false });
        setNotifications(notifications || []);
      } else if (activeTab === 'saved') {
        const { data: saved } = await supabase
          .from('saved_articles')
          .select('*, article:articles(*)')
          .eq('user_id', user?.id)
          .order('created_at', { ascending: false });
        setSavedArticles(saved?.map(s => ({
          id: s.article.id,
          title: s.article.title,
          excerpt: s.article.excerpt,
          image_url: s.article.image_url,
          saved_at: s.created_at
        })) || []);
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleProfileUpdate = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      setSaving(true);

      const { error: updateError } = await supabase
        .from('profiles')
        .update({
          username: profileForm.username,
          bio: profileForm.bio,
          avatar_url: profileForm.avatar_url
        })
        .eq('id', user?.id);

      if (updateError) throw updateError;

      // Refresh the auth context to update the profile everywhere
      await refreshProfile();

      setSuccess('Profile updated successfully');
      setTimeout(() => setSuccess(null), 3000);
    } catch (error) {
      console.error('Error updating profile:', error);
      setError(error instanceof Error ? error.message : 'Failed to update profile');
      setTimeout(() => setError(null), 3000);
    } finally {
      setSaving(false);
    }
  };

  const handleAvatarUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const file = e.target.files?.[0];
      if (!file) return;

      const fileExt = file.name.split('.').pop();
      const filePath = `${user?.id}/${Math.random()}.${fileExt}`;

      const { error: uploadError } = await supabase.storage
        .from('avatars')
        .upload(filePath, file);

      if (uploadError) throw uploadError;

      const { data: { publicUrl } } = supabase.storage
        .from('avatars')
        .getPublicUrl(filePath);

      setProfileForm(prev => ({ ...prev, avatar_url: publicUrl }));
    } catch (error) {
      console.error('Error uploading avatar:', error);
      setError('Failed to upload avatar');
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 pt-24">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col md:flex-row gap-8">
          {/* Sidebar */}
          <div className="w-full md:w-64">
            <div className="bg-white rounded-lg shadow overflow-hidden">
              <div className="p-6">
                <div className="flex items-center space-x-4">
                  <img
                    src={profile?.avatar_url || 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80'}
                    alt={profile?.username || user?.email}
                    className="h-12 w-12 rounded-full"
                  />
                  <div>
                    <h2 className="text-lg font-medium text-gray-900">
                      {profile?.username || user?.email?.split('@')[0]}
                    </h2>
                    <p className="text-sm text-gray-500">{user?.email}</p>
                  </div>
                </div>
              </div>
              <nav className="space-y-1">
                <button
                  onClick={() => setActiveTab('notifications')}
                  className={`w-full flex items-center px-6 py-3 text-sm font-medium ${
                    activeTab === 'notifications'
                      ? 'bg-[#B6AC7D] text-white'
                      : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900'
                  }`}
                >
                  <Bell className="h-5 w-5 mr-3" />
                  Notifications
                </button>
                <button
                  onClick={() => setActiveTab('saved')}
                  className={`w-full flex items-center px-6 py-3 text-sm font-medium ${
                    activeTab === 'saved'
                      ? 'bg-[#B6AC7D] text-white'
                      : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900'
                  }`}
                >
                  <Bookmark className="h-5 w-5 mr-3" />
                  Saved Articles
                </button>
                <button
                  onClick={() => setActiveTab('settings')}
                  className={`w-full flex items-center px-6 py-3 text-sm font-medium ${
                    activeTab === 'settings'
                      ? 'bg-[#B6AC7D] text-white'
                      : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900'
                  }`}
                >
                  <Settings className="h-5 w-5 mr-3" />
                  Settings
                </button>
              </nav>
            </div>
          </div>

          {/* Main Content */}
          <div className="flex-1">
            <div className="bg-white rounded-lg shadow">
              {activeTab === 'settings' ? (
                <div className="p-6">
                  <h2 className="text-lg font-medium text-gray-900 mb-6">Profile Settings</h2>
                  
                  {error && (
                    <div className="mb-4 bg-red-50 text-red-600 p-3 rounded-lg">
                      {error}
                    </div>
                  )}
                  
                  {success && (
                    <div className="mb-4 bg-green-50 text-green-600 p-3 rounded-lg">
                      {success}
                    </div>
                  )}

                  <form onSubmit={handleProfileUpdate} className="space-y-6">
                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Profile Picture
                      </label>
                      <div className="mt-2 flex items-center space-x-4">
                        <img
                          src={profileForm.avatar_url || 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80'}
                          alt="Profile"
                          className="h-12 w-12 rounded-full"
                        />
                        <label className="cursor-pointer bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#B6AC7D]">
                          Change
                          <input
                            type="file"
                            className="hidden"
                            accept="image/*"
                            onChange={handleAvatarUpload}
                          />
                        </label>
                      </div>
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Username
                      </label>
                      <input
                        type="text"
                        value={profileForm.username}
                        onChange={(e) => setProfileForm(prev => ({ ...prev, username: e.target.value }))}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#B6AC7D] focus:ring-[#B6AC7D]"
                      />
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Bio
                      </label>
                      <textarea
                        value={profileForm.bio}
                        onChange={(e) => setProfileForm(prev => ({ ...prev, bio: e.target.value }))}
                        rows={4}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#B6AC7D] focus:ring-[#B6AC7D]"
                      />
                    </div>

                    <div className="flex justify-end">
                      <button
                        type="submit"
                        disabled={saving}
                        className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-[#B6AC7D] hover:bg-[#A49C6D] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#B6AC7D]"
                      >
                        {saving ? 'Saving...' : 'Save Changes'}
                      </button>
                    </div>
                  </form>
                </div>
              ) : activeTab === 'notifications' ? (
                <div className="divide-y divide-gray-200">
                  {loading ? (
                    <div className="p-4">Loading...</div>
                  ) : notifications.length === 0 ? (
                    <div className="p-6 text-center">
                      <Bell className="mx-auto h-12 w-12 text-gray-400" />
                      <h3 className="mt-2 text-sm font-medium text-gray-900">No notifications</h3>
                      <p className="mt-1 text-sm text-gray-500">
                        You're all caught up!
                      </p>
                    </div>
                  ) : (
                    notifications.map((notification: any) => (
                      <div key={notification.id} className="p-4 hover:bg-gray-50">
                        <div className="flex items-center space-x-4">
                          <div className="flex-1">
                            <p className="text-sm text-gray-900">{notification.content}</p>
                            <p className="text-xs text-gray-500">
                              {new Date(notification.created_at).toLocaleDateString()}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              ) : (
                <div className="divide-y divide-gray-200">
                  {loading ? (
                    <div className="p-4">Loading...</div>
                  ) : savedArticles.length === 0 ? (
                    <div className="p-6 text-center">
                      <Bookmark className="mx-auto h-12 w-12 text-gray-400" />
                      <h3 className="mt-2 text-sm font-medium text-gray-900">No saved articles</h3>
                      <p className="mt-1 text-sm text-gray-500">
                        Start saving articles to read them later
                      </p>
                    </div>
                  ) : (
                    savedArticles.map((article: any) => (
                      <div key={article.id} className="p-4 hover:bg-gray-50">
                        <div className="flex items-center space-x-4">
                          <img
                            src={article.image_url}
                            alt={article.title}
                            className="h-16 w-16 object-cover rounded"
                          />
                          <div className="flex-1">
                            <h3 className="text-sm font-medium text-gray-900">{article.title}</h3>
                            <p className="text-sm text-gray-500">{article.excerpt}</p>
                            <p className="text-xs text-gray-400">
                              Saved on {new Date(article.saved_at).toLocaleDateString()}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Account;