import React from 'react';
import { Link } from 'react-router-dom';
import { Heart, Eye, MessageSquare, Clock } from 'lucide-react';
import { formatDistanceToNow } from 'date-fns';
import { truncateText } from '../utils/textUtils';

interface ArticleCardProps {
  article: {
    slug: string;
    title: string;
    excerpt: string;
    imageUrl: string;
    author: {
      name: string;
      avatar: string;
    };
    createdAt: string;
    stats: {
      viewCount: number;
      likeCount: number;
      commentCount: number;
    };
  };
  featured?: boolean;
}

const ArticleCard: React.FC<ArticleCardProps> = ({ article, featured = false }) => {
  return (
    <Link 
      to={`/news/${article.slug}`} 
      className={`group block ${featured ? 'h-full' : ''}`}
    >
      <article className={`bg-white rounded-xl shadow-sm overflow-hidden hover:shadow-lg transition-shadow duration-300 ${featured ? 'h-full flex flex-col' : ''}`}>
        <div className="relative aspect-[16/9] overflow-hidden">
          <img
            src={article.imageUrl}
            alt={article.title}
            className="w-full h-full object-cover transform group-hover:scale-105 transition-transform duration-500"
            loading="lazy"
          />
          <div className="absolute inset-0 bg-gradient-to-t from-black/80 via-black/40 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300">
            <div className="absolute bottom-0 p-6 w-full">
              <div className="flex items-center space-x-2">
                <img
                  src={article.author.avatar}
                  alt={article.author.name}
                  className="w-8 h-8 rounded-full border-2 border-white"
                  loading="lazy"
                />
                <span className="text-white text-sm font-medium">{article.author.name}</span>
              </div>
            </div>
          </div>
        </div>
        
        <div className={`p-6 ${featured ? 'flex-1 flex flex-col' : ''}`}>
          <h3 className="text-xl font-bold text-gray-900 mb-2 line-clamp-2 group-hover:text-[#B6AC7D] transition-colors">
            {article.title}
          </h3>
          <p className="text-gray-600 mb-4 line-clamp-2 flex-grow">
            {truncateText(article.excerpt)}
          </p>
          
          <div className="flex items-center justify-between text-sm text-gray-500 mt-auto">
            <div className="flex items-center space-x-4">
              <span className="flex items-center">
                <Eye className="h-4 w-4 mr-1" />
                {article.stats.viewCount.toLocaleString()}
              </span>
              <span className="flex items-center">
                <Heart className="h-4 w-4 mr-1" />
                {article.stats.likeCount.toLocaleString()}
              </span>
              <span className="flex items-center">
                <MessageSquare className="h-4 w-4 mr-1" />
                {article.stats.commentCount.toLocaleString()}
              </span>
            </div>
            <span className="flex items-center whitespace-nowrap">
              <Clock className="h-4 w-4 mr-1" />
              {formatDistanceToNow(new Date(article.createdAt), { addSuffix: true })}
            </span>
          </div>
        </div>
      </article>
    </Link>
  );
};

export default ArticleCard;