import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../lib/supabase';
import { useAuth } from '../contexts/AuthContext';
import WritingEditor from '../components/WritingEditor';
import { marked } from 'marked';

const NewArticle = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [error, setError] = useState<string | null>(null);
  const [isDraft, setIsDraft] = useState(true);

  useEffect(() => {
    if (!user) {
      navigate('/auth');
      return;
    }
  }, [user, navigate]);

  const generateUniqueSlug = (title: string): string => {
    const timestamp = Date.now().toString(36);
    return `${title
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, '-')
      .replace(/(^-|-$)/g, '')}-${timestamp}`;
  };

  const stripHtml = (html: string): string => {
    const tmp = document.createElement('div');
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || '';
  };

  const handleSave = async (content: string, title: string, metadata: any, publish: boolean = false) => {
    try {
      if (!title.trim()) {
        throw new Error('Please enter a title for your article');
      }

      if (publish) {
        if (!content.trim()) {
          throw new Error('Please add content before publishing');
        }
        
        if (!metadata.coverImage) {
          throw new Error('Please add a cover image before publishing');
        }

        if (!metadata.category) {
          throw new Error('Please select a category before publishing');
        }
      }

      // Create unique URL-friendly slug
      const slug = generateUniqueSlug(title);

      // Convert markdown to HTML then strip tags to get plain text
      const htmlContent = marked(content);
      const plainText = stripHtml(htmlContent);

      // Get first 140 characters for excerpt
      const excerpt = plainText.substring(0, 140).trim() + '...';

      const { data: article, error: saveError } = await supabase
        .from('articles')
        .insert({
          title,
          slug,
          content,
          excerpt,
          author_id: user?.id,
          word_count: metadata.wordCount || 0,
          reading_time: metadata.readingTime || 0,
          status: publish ? 'published' : 'draft',
          image_url: metadata.coverImage,
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString()
        })
        .select()
        .single();

      if (saveError) throw saveError;

      // Show success message
      setError('Article saved successfully');
      setTimeout(() => setError(null), 3000);

      if (publish) {
        navigate(`/news/${article.slug}`);
      } else {
        navigate('/writer');
      }
    } catch (err) {
      console.error('Error saving article:', err);
      setError(err instanceof Error ? err.message : 'Failed to save article');
      throw err;
    }
  };

  const handleImageUpload = async (file: File): Promise<string> => {
    try {
      // Validate file type
      const validTypes = ['image/jpeg', 'image/png', 'image/webp'];
      if (!validTypes.includes(file.type)) {
        throw new Error('Please upload a valid image file (JPEG, PNG, or WebP)');
      }

      // Validate file size (max 5MB)
      const maxSize = 5 * 1024 * 1024;
      if (file.size > maxSize) {
        throw new Error('Image size must be less than 5MB');
      }

      const fileExt = file.name.split('.').pop();
      const fileName = `${Math.random().toString(36).substring(2)}.${fileExt}`;
      const filePath = `${fileName}`;

      const { error: uploadError } = await supabase.storage
        .from('article-thumbnails')
        .upload(filePath, file);

      if (uploadError) throw uploadError;

      const { data: { publicUrl } } = supabase.storage
        .from('article-thumbnails')
        .getPublicUrl(filePath);

      return publicUrl;
    } catch (error) {
      console.error('Error uploading image:', error);
      throw error instanceof Error ? error : new Error('Failed to upload image');
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-[#B6AC7D]/10 via-white to-[#B6AC7D]/5">
      {error && (
        <div className="fixed top-20 left-1/2 transform -translate-x-1/2 bg-red-50 border border-red-200 text-red-600 px-4 py-3 rounded-lg z-50">
          {error}
        </div>
      )}

      <WritingEditor
        onSave={handleSave}
        onImageUpload={handleImageUpload}
        isDraft={isDraft}
        setIsDraft={setIsDraft}
      />
    </div>
  );
};

export default NewArticle;