import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { supabase } from '../lib/supabase';
import { useAuth } from '../contexts/AuthContext';
import WritingEditor from '../components/WritingEditor';
import { marked } from 'marked';

const EditArticle = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { user, profile } = useAuth();
  const [article, setArticle] = useState<any>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkAccess = async () => {
      try {
        if (!user) {
          navigate('/auth');
          return;
        }

        if (!id) {
          navigate('/404');
          return;
        }

        // Check if user has permission to edit this article
        const { data: article, error: articleError } = await supabase
          .from('articles')
          .select(`
            *,
            author:profiles(username)
          `)
          .eq('id', id)
          .single();

        if (articleError) {
          console.error('Error fetching article:', articleError);
          setError('Article not found');
          return;
        }

        if (!article) {
          setError('Article not found');
          return;
        }

        // Only allow author or admin to edit
        if (article.author_id !== user.id && profile?.role !== 'admin') {
          navigate('/');
          return;
        }

        setArticle(article);
      } catch (error) {
        console.error('Error checking access:', error);
        setError('Error checking access. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    checkAccess();
  }, [user, id, navigate, profile]);

  const stripHtml = (html: string): string => {
    const tmp = document.createElement('div');
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || '';
  };

  const handleSave = async (content: string, title: string, metadata: any, publish: boolean = false) => {
    try {
      setError(null);

      if (!title.trim()) {
        throw new Error('Please enter a title for your article');
      }

      if (publish) {
        if (!content.trim()) {
          throw new Error('Please add content before publishing');
        }
        
        if (!metadata.coverImage) {
          throw new Error('Please add a cover image before publishing');
        }

        if (!metadata.category) {
          throw new Error('Please select a category before publishing');
        }
      }

      // Convert markdown to HTML then strip tags to get plain text
      const htmlContent = marked(content);
      const plainText = stripHtml(htmlContent);

      // Get first 140 characters for excerpt
      const excerpt = plainText.substring(0, 140).trim() + '...';

      const { error: saveError } = await supabase
        .from('articles')
        .update({
          title,
          content,
          excerpt,
          word_count: metadata.wordCount || 0,
          reading_time: metadata.readingTime || 0,
          status: publish ? 'published' : 'draft',
          image_url: metadata.coverImage || article.image_url,
          category: metadata.category,
          tags: metadata.tags || [],
          updated_at: new Date().toISOString()
        })
        .eq('id', id);

      if (saveError) {
        console.error('Error saving article:', saveError);
        throw new Error('Failed to save article. Please try again.');
      }

      // Show success message
      setError('Article saved successfully');
      setTimeout(() => setError(null), 3000);

      if (publish) {
        navigate('/writer');
      }
    } catch (err) {
      console.error('Error saving article:', err);
      setError(err instanceof Error ? err.message : 'Failed to save article');
      throw err;
    }
  };

  const handleImageUpload = async (file: File): Promise<string> => {
    try {
      // Validate file type
      const validTypes = ['image/jpeg', 'image/png', 'image/webp'];
      if (!validTypes.includes(file.type)) {
        throw new Error('Please upload a valid image file (JPEG, PNG, or WebP)');
      }

      // Validate file size (max 5MB)
      const maxSize = 5 * 1024 * 1024;
      if (file.size > maxSize) {
        throw new Error('Image size must be less than 5MB');
      }

      const fileExt = file.name.split('.').pop();
      const fileName = `${Math.random().toString(36).substring(2)}.${fileExt}`;
      const filePath = `${fileName}`;

      const { error: uploadError } = await supabase.storage
        .from('article-thumbnails')
        .upload(filePath, file);

      if (uploadError) throw uploadError;

      const { data: { publicUrl } } = supabase.storage
        .from('article-thumbnails')
        .getPublicUrl(filePath);

      return publicUrl;
    } catch (error) {
      console.error('Error uploading image:', error);
      throw error instanceof Error ? error : new Error('Failed to upload image');
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-white flex items-center justify-center">
        <div className="w-16 h-16 border-4 border-[#B6AC7D] border-t-transparent rounded-full animate-spin"></div>
      </div>
    );
  }

  if (error && !article) {
    return (
      <div className="min-h-screen bg-white flex items-center justify-center">
        <div className="text-center">
          <h2 className="text-2xl font-bold text-gray-900 mb-4">Error Loading Article</h2>
          <p className="text-gray-600 mb-6">{error}</p>
          <button
            onClick={() => navigate('/writer')}
            className="px-4 py-2 bg-[#B6AC7D] text-white rounded-lg hover:bg-[#A49C6D]"
          >
            Return to Dashboard
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-[#B6AC7D]/10 via-white to-[#B6AC7D]/5">
      {error && (
        <div className="fixed top-20 left-1/2 transform -translate-x-1/2 bg-red-50 border border-red-200 text-red-600 px-4 py-3 rounded-lg z-50">
          {error}
        </div>
      )}

      <WritingEditor
        initialContent={article.content || ''}
        initialTitle={article.title || ''}
        initialCoverImage={article.image_url || ''}
        initialCategory={article.category || ''}
        initialTags={article.tags || []}
        onSave={handleSave}
        onImageUpload={handleImageUpload}
        isDraft={article.status === 'draft'}
      />
    </div>
  );
};

export default EditArticle;