import React, { useState } from 'react';
import { ChevronRight } from 'lucide-react';

interface DropdownProps {
  title: string;
  children: React.ReactNode;
}

const Dropdown: React.FC<DropdownProps> = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="dropdown" data-open={isOpen}>
      <button
        className="dropdown-trigger"
        onClick={() => setIsOpen(!isOpen)}
        aria-expanded={isOpen}
      >
        <span className="flex items-center">
          <ChevronRight className="chevron-icon mr-2 h-5 w-5" />
          <span className="font-medium">{title}</span>
        </span>
      </button>
      <div className="dropdown-content" data-open={isOpen}>
        {children}
      </div>
    </div>
  );
};

export default Dropdown;