import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Search, Menu, X, ChevronDown, LogOut, User, Settings, Edit } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import { supabase } from '../lib/supabase';
import { marked } from 'marked';

export const categories = [
  { id: 'all', name: 'All News', description: 'Explore every story, all in one place.' },
  { id: 'world-news', name: 'World News', description: 'World coverage of major events shaping our world today.' },
  { id: 'faith', name: 'Faith', description: 'Inspiring news stories rooted in faith and eternal truth.' },
  { id: 'lifestyle', name: 'Lifestyle', description: 'Insights on health, wellness, family, and daily living.' },
  { id: 'business', name: 'Business', description: 'Economic trends, markets, entrepreneurship, and business news.' },
  { id: 'entertainment', name: 'Entertainment', description: 'Stories at the crossroads of faith, arts, culture, and media.' },
  { id: 'sports', name: 'Sports', description: 'Highlights, analysis, and inspiring stories from the world of sports.' },
  { id: 'tech', name: 'Tech', description: 'Cutting-edge technological innovations and digital trends.' },
  { id: 'science', name: 'Science', description: 'Breakthrough discoveries, environmental news, stewarding God\'s Creation.' },
  { id: 'investigations', name: 'Investigations', description: 'In-depth investigations and exposés uncovering The Truth.' },
  { id: 'voices', name: 'Voices & Views', description: 'Opinion pieces and editorials from an Eternal perspective.' }
];

const Navbar = () => {
  const { user, profile, signOut, loading } = useAuth();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showCategories, setShowCategories] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [isSearching, setIsSearching] = useState(false);
  const [showSearchResults, setShowSearchResults] = useState(false);
  const searchRef = useRef<HTMLDivElement>(null);
  const categoriesRef = useRef<HTMLDivElement>(null);
  const profileRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [scrolled, setScrolled] = useState(false);

  // Reset search when changing routes
  useEffect(() => {
    setSearchQuery('');
    setSearchResults([]);
    setShowSearchResults(false);
    setIsMenuOpen(false);
  }, [location.pathname]);

  // Handle click outside search results
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (searchRef.current && !searchRef.current.contains(event.target as Node)) {
        setShowSearchResults(false);
      }
      if (categoriesRef.current && !categoriesRef.current.contains(event.target as Node)) {
        setShowCategories(false);
      }
      if (profileRef.current && !profileRef.current.contains(event.target as Node)) {
        setShowProfileMenu(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  // Handle scroll effect
  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const stripHtml = (html: string): string => {
    const tmp = document.createElement('div');
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || '';
  };

  const handleSearch = async (e?: React.FormEvent) => {
    if (e) {
      e.preventDefault();
    }

    if (!searchQuery.trim()) {
      setSearchResults([]);
      setShowSearchResults(false);
      return;
    }

    setIsSearching(true);
    try {
      // First try exact match with article title
      const { data: exactMatches, error: exactError } = await supabase
        .from('articles')
        .select(`
          *,
          author:profiles(username, avatar_url),
          views(count),
          likes(count),
          comments(count)
        `)
        .eq('status', 'published')
        .ilike('title', `%${searchQuery}%`)
        .limit(3);

      if (exactError) throw exactError;

      // Then try full-text search on content
      const { data: contentMatches, error: contentError } = await supabase
        .from('articles')
        .select(`
          *,
          author:profiles(username, avatar_url),
          views(count),
          likes(count),
          comments(count)
        `)
        .eq('status', 'published')
        .textSearch('content', searchQuery)
        .not('id', 'in', (exactMatches || []).map(m => m.id))
        .limit(5);

      if (contentError) throw contentError;

      // Combine and transform results
      const allResults = [...(exactMatches || []), ...(contentMatches || [])].map(article => {
        // Convert markdown to plain text for excerpt
        const htmlContent = marked(article.content || '');
        const plainText = stripHtml(htmlContent);
        
        // Find the most relevant excerpt containing the search term
        const searchTermIndex = plainText.toLowerCase().indexOf(searchQuery.toLowerCase());
        let excerpt = '';
        
        if (searchTermIndex >= 0) {
          const start = Math.max(0, searchTermIndex - 50);
          const end = Math.min(plainText.length, searchTermIndex + searchQuery.length + 50);
          excerpt = '...' + plainText.slice(start, end) + '...';
        } else {
          excerpt = plainText.substring(0, 100) + '...';
        }

        return {
          id: article.id,
          slug: article.slug,
          title: article.title,
          excerpt,
          imageUrl: article.image_url,
          author: {
            name: article.author?.username || 'Anonymous',
            avatar: article.author?.avatar_url || 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e',
          },
          stats: {
            viewCount: article.views?.[0]?.count || 0,
            likeCount: article.likes?.[0]?.count || 0,
            commentCount: article.comments?.[0]?.count || 0,
          }
        };
      });

      setSearchResults(allResults);
      setShowSearchResults(true);
    } catch (error) {
      console.error('Search error:', error);
    } finally {
      setIsSearching(false);
    }
  };

  // Debounce search
  useEffect(() => {
    const timer = setTimeout(() => {
      if (searchQuery) {
        handleSearch();
      }
    }, 300);

    return () => clearTimeout(timer);
  }, [searchQuery]);

  const handleSearchSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (searchQuery.trim()) {
      navigate(`/all-articles?search=${encodeURIComponent(searchQuery)}`);
      setShowSearchResults(false);
    }
  };

  const handleSignOut = async () => {
    try {
      await signOut();
      setShowProfileMenu(false);
      setIsMenuOpen(false);
    } catch (error) {
      console.error('Error signing out:', error);
      alert('Failed to sign out. Please try again.');
    }
  };

  return (
    <nav className={`fixed w-full top-0 z-50 transition-all duration-300 ${
      scrolled ? 'bg-white shadow-md' : 'bg-transparent backdrop-blur-sm'
    }`}>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-24">
          <div className="flex items-center">
            <Link to="/" className="flex items-center space-x-2">
              <img
                src="https://owkthkmwmioqetsacfth.supabase.co/storage/v1/object/public/logos/Clarity%20News%20Logo.png"
                alt="CN Logo"
                className="h-16 w-auto"
              />
            </Link>
          </div>

          {/* Desktop Navigation */}
          <div className="hidden md:flex items-center space-x-8">
            <div className="relative" ref={searchRef}>
              <form onSubmit={handleSearchSubmit}>
                <div className="relative">
                  <input
                    type="text"
                    placeholder="Search articles..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className={`w-64 pl-10 pr-4 py-2 rounded-full ${
                      scrolled 
                        ? 'bg-gray-100 border border-gray-200 text-gray-900 placeholder-gray-500'
                        : 'bg-white/10 backdrop-blur-md border border-white/20 text-gray-900 placeholder-gray-800'
                    } focus:outline-none focus:ring-2 focus:ring-[#B6AC7D] focus:border-transparent transition-all duration-300`}
                  />
                  <button
                    type="submit"
                    className="absolute right-3 top-2.5 text-gray-500 hover:text-gray-700"
                  >
                    <Search className="h-5 w-5" />
                  </button>
                </div>
              </form>

              {/* Search Results Dropdown */}
              {showSearchResults && searchResults.length > 0 && (
                <div className="absolute top-full left-0 w-[400px] mt-2 bg-white rounded-lg shadow-lg overflow-hidden z-50">
                  <div className="max-h-[400px] overflow-y-auto">
                    {searchResults.map((result) => (
                      <Link
                        key={result.id}
                        to={`/news/${result.slug}`}
                        className="block p-4 hover:bg-gray-50"
                        onClick={() => setShowSearchResults(false)}
                      >
                        <div className="flex gap-4">
                          <img
                            src={result.imageUrl}
                            alt={result.title}
                            className="w-16 h-16 object-cover rounded"
                          />
                          <div className="flex-1 min-w-0">
                            <h3 className="text-sm font-medium text-gray-900 line-clamp-1">
                              {result.title}
                            </h3>
                            <p className="text-xs text-gray-500 mt-1 line-clamp-2">
                              {result.excerpt}
                            </p>
                          </div>
                        </div>
                      </Link>
                    ))}
                    
                    {searchQuery && (
                      <div className="p-3 bg-gray-50 border-t border-gray-100">
                        <button
                          onClick={() => {
                            navigate(`/all-articles?search=${encodeURIComponent(searchQuery)}`);
                            setShowSearchResults(false);
                          }}
                          className="w-full text-center text-sm text-[#B6AC7D] hover:text-[#A49C6D]"
                        >
                          View all results for "{searchQuery}"
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              )}

              {isSearching && (
                <div className="absolute top-full left-0 w-[400px] mt-2 bg-white rounded-lg shadow-lg p-4 text-center z-50">
                  <div className="animate-pulse">
                    <div className="h-4 bg-gray-200 rounded w-3/4 mx-auto"></div>
                    <div className="h-4 bg-gray-200 rounded w-1/2 mx-auto mt-2"></div>
                  </div>
                </div>
              )}
            </div>

            <div className="flex items-center space-x-6">
              <div className="relative" ref={categoriesRef}>
                <button
                  onClick={() => setShowCategories(!showCategories)}
                  className={`flex items-center text-sm font-medium ${
                    scrolled ? 'text-gray-900' : 'text-gray-900'
                  } hover:text-[#B6AC7D] transition-colors`}
                >
                  Categories
                  <ChevronDown className="h-4 w-4 ml-1" />
                </button>
                {showCategories && (
                  <div className="absolute top-full left-0 w-64 mt-2 bg-white rounded-lg shadow-lg py-2 z-50">
                    {categories.map((category) => (
                      <Link
                        key={category.id}
                        to={`/category/${category.id}`}
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-50"
                        onClick={() => setShowCategories(false)}
                      >
                        {category.name}
                      </Link>
                    ))}
                  </div>
                )}
              </div>
              {user ? (
                <div className="relative" ref={profileRef}>
                  <button
                    onClick={() => setShowProfileMenu(!showProfileMenu)}
                    className="flex items-center space-x-2"
                  >
                    <img
                      src={profile?.avatar_url || 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80'}
                      alt={profile?.username || user.email}
                      className="h-8 w-8 rounded-full border-2 border-white/20"
                    />
                  </button>
                  {showProfileMenu && (
                    <div className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg py-1 z-50">
                      <Link
                        to="/account"
                        className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-50"
                        onClick={() => setShowProfileMenu(false)}
                      >
                        <User className="h-4 w-4 mr-2" />
                        Profile
                      </Link>
                      {profile?.role === 'admin' && (
                        <Link
                          to="/admin"
                          className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-50"
                          onClick={() => setShowProfileMenu(false)}
                        >
                          <Settings className="h-4 w-4 mr-2" />
                          Admin Dashboard
                        </Link>
                      )}
                      {(profile?.role === 'admin' || profile?.role === 'writer') && (
                        <Link
                          to="/writer"
                          className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-50"
                          onClick={() => setShowProfileMenu(false)}
                        >
                          <Edit className="h-4 w-4 mr-2" />
                          Writer Dashboard
                        </Link>
                      )}
                      <button
                        onClick={handleSignOut}
                        disabled={loading}
                        className={`flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-50 ${
                          loading ? 'opacity-50 cursor-not-allowed' : ''
                        }`}
                      >
                        <LogOut className="h-4 w-4 mr-2" />
                        {loading ? 'Signing out...' : 'Sign Out'}
                      </button>
                    </div>
                  )}
                </div>
              ) : (
                <Link
                  to="/auth"
                  className={`text-sm font-medium ${
                    scrolled ? 'text-gray-900' : 'text-gray-900'
                  } hover:text-[#B6AC7D] transition-colors`}
                >
                  Log In
                </Link>
              )}
            </div>
          </div>

          {/* Mobile menu button */}
          <div className="md:hidden flex items-center">
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-900 hover:text-[#B6AC7D] focus:outline-none focus:ring-2 focus:ring-inset focus:ring-[#B6AC7D]"
            >
              {isMenuOpen ? (
                <X className="block h-6 w-6" />
              ) : (
                <Menu className="block h-6 w-6" />
              )}
            </button>
          </div>
        </div>
      </div>

      {/* Mobile menu */}
      {isMenuOpen && (
        <div className="md:hidden bg-white/95 backdrop-blur-md">
          <div className="px-2 pt-2 pb-3 space-y-1">
            <div className="relative mx-2 mb-4">
              <input
                type="text"
                placeholder="Search articles..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="w-full px-4 py-2 rounded-full bg-gray-100 focus:outline-none focus:ring-2 focus:ring-[#B6AC7D]"
              />
              <Search className="absolute right-3 top-2.5 h-5 w-5 text-gray-400" />
            </div>

            {/* Mobile Categories */}
            <div className="px-3 py-2 text-base font-medium text-gray-900">
              Categories
            </div>
            {categories.map((category) => (
              <Link
                key={category.id}
                to={`/category/${category.id}`}
                className="block px-3 py-2 text-sm text-gray-600 hover:text-[#B6AC7D] hover:bg-gray-50 rounded-md"
                onClick={() => setIsMenuOpen(false)}
              >
                {category.name}
              </Link>
            ))}

            {user ? (
              <div className="space-y-2">
                <Link
                  to="/account"
                  className="flex items-center space-x-3 px-3 py-2 hover:bg-gray-50 rounded-md"
                  onClick={() => setIsMenuOpen(false)}
                >
                  <img
                    src={profile?.avatar_url || 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80'}
                    alt={profile?.username || 'Profile'}
                    className="h-8 w-8 rounded-full"
                  />
                  <span className="text-gray-900">{profile?.username || user.email?.split('@')[0]}</span>
                </Link>
                {profile?.role === 'admin' && (
                  <Link
                    to="/admin"
                    className="block px-3 py-2 text-gray-900 hover:text-[#B6AC7D] hover:bg-gray-50 rounded-md"
                    onClick={() => setIsMenuOpen(false)}
                  >
                    Admin Dashboard
                  </Link>
                )}
                {(profile?.role === 'admin' || profile?.role === 'writer') && (
                  <Link
                    to="/writer"
                    className="block px-3 py-2 text-gray-900 hover:text-[#B6AC7D] hover:bg-gray-50 rounded-md"
                    onClick={() => setIsMenuOpen(false)}
                  >
                    Writer Dashboard
                  </Link>
                )}
                <button
                  onClick={handleSignOut}
                  disabled={loading}
                  className={`w-full text-left px-3 py-2 text-gray-900 hover:text-[#B6AC7D] hover:bg-gray-50 rounded-md ${
                    loading ? 'opacity-50 cursor-not-allowed' : ''
                  }`}
                >
                  {loading ? 'Signing out...' : 'Sign Out'}
                </button>
              </div>
            ) : (
              <Link
                to="/auth"
                className="block px-3 py-2 text-base font-medium text-gray-900 hover:text-[#B6AC7D] hover:bg-gray-50 rounded-md"
                onClick={() => setIsMenuOpen(false)}
              >
                Log In
              </Link>
            )}
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;