import React, { useState, useEffect } from 'react';
import { supabase } from '../lib/supabase';
import { formatDistanceToNow } from 'date-fns';
import { TrendingUp, Clock, Filter, Search, ArrowUpRight } from 'lucide-react';
import { Link } from 'react-router-dom';

interface Article {
  id: string;
  slug: string;
  title: string;
  excerpt: string;
  imageUrl: string;
  content: string;
  author: {
    name: string;
    avatar: string;
  };
  createdAt: string;
  stats: {
    viewCount: number;
    likeCount: number;
    commentCount: number;
  };
}

const Latest = () => {
  const [articles, setArticles] = useState<Article[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [sortBy, setSortBy] = useState('latest');

  useEffect(() => {
    fetchArticles();
  }, [selectedCategory, sortBy]);

  const fetchArticles = async () => {
    try {
      setLoading(true);
      const { data, error } = await supabase
        .from('articles')
        .select(`
          *,
          author:profiles(*),
          views:views(id),
          likes:likes(id),
          comments:comments(id)
        `)
        .order('created_at', { ascending: false });

      if (error) throw error;

      if (data) {
        const transformedArticles = data.map(article => ({
          id: article.id,
          slug: article.slug,
          title: article.title,
          excerpt: article.excerpt || '',
          content: article.content,
          imageUrl: article.image_url,
          author: {
            name: article.author?.username || 'Anonymous',
            avatar: article.author?.avatar_url || 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e',
          },
          createdAt: article.created_at,
          stats: {
            viewCount: article.views?.length || 0,
            likeCount: article.likes?.length || 0,
            commentCount: article.comments?.length || 0,
          },
        }));
        setArticles(transformedArticles);
      }
    } catch (error) {
      console.error('Error fetching articles:', error);
    } finally {
      setLoading(false);
    }
  };

  const filteredArticles = articles.filter(article =>
    article.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
    article.excerpt.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="min-h-screen bg-white pt-24">
      {/* Hero Section */}
      <div className="relative bg-gradient-to-br from-[#B6AC7D]/10 via-white to-[#B6AC7D]/5 py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h1 className="text-5xl font-bold text-gray-900 mb-6">
              Latest News & Stories
            </h1>
            <p className="text-xl text-gray-600 max-w-2xl mx-auto">
              Stay informed with our latest coverage of important stories and events, 
              delivered with clarity and integrity.
            </p>
          </div>
        </div>
      </div>

      {/* Filters Section */}
      <div className="border-b border-gray-200">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
          <div className="flex flex-col md:flex-row md:items-center justify-between gap-4">
            <div className="relative flex-1 max-w-lg">
              <input
                type="text"
                placeholder="Search articles..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="w-full pl-10 pr-4 py-2 rounded-lg border border-gray-200 focus:ring-2 focus:ring-[#B6AC7D] focus:border-transparent"
              />
              <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
            </div>
            <div className="flex items-center space-x-4">
              <div className="flex items-center space-x-2">
                <Filter className="h-5 w-5 text-gray-400" />
                <select
                  value={sortBy}
                  onChange={(e) => setSortBy(e.target.value)}
                  className="pl-3 pr-10 py-2 text-base border-gray-200 focus:outline-none focus:ring-[#B6AC7D] focus:border-[#B6AC7D] rounded-lg"
                >
                  <option value="latest">Latest First</option>
                  <option value="trending">Trending</option>
                  <option value="discussed">Most Discussed</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Articles Grid */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        {loading ? (
          <div className="grid grid-cols-1 gap-12 animate-pulse">
            {[1, 2, 3].map((n) => (
              <div key={n} className="flex flex-col md:flex-row gap-8">
                <div className="w-full md:w-2/5 h-64 bg-gray-200 rounded-xl"></div>
                <div className="flex-1 space-y-4">
                  <div className="h-4 bg-gray-200 rounded w-1/4"></div>
                  <div className="h-8 bg-gray-200 rounded w-3/4"></div>
                  <div className="h-4 bg-gray-200 rounded w-full"></div>
                  <div className="h-4 bg-gray-200 rounded w-2/3"></div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="space-y-12">
            {filteredArticles.map((article) => (
              <Link
                key={article.id}
                to={`/news/${article.slug}`}
                className="group block"
              >
                <article className="flex flex-col md:flex-row gap-8 hover:bg-gray-50 p-4 rounded-xl transition-colors">
                  <div className="w-full md:w-2/5 aspect-[4/3] overflow-hidden rounded-xl">
                    <img
                      src={article.imageUrl}
                      alt={article.title}
                      className="w-full h-full object-cover group-hover:scale-105 transition-transform duration-300"
                    />
                  </div>
                  <div className="flex-1 space-y-4">
                    <div className="flex items-center space-x-2 text-sm text-gray-500">
                      <Clock className="h-4 w-4" />
                      <span>{formatDistanceToNow(new Date(article.createdAt), { addSuffix: true })}</span>
                    </div>
                    <h2 className="text-2xl font-bold text-gray-900 group-hover:text-[#B6AC7D] transition-colors">
                      {article.title}
                    </h2>
                    <p className="text-gray-600 line-clamp-2">{article.excerpt}</p>
                    <div className="flex items-center justify-between pt-4">
                      <div className="flex items-center space-x-3">
                        <img
                          src={article.author.avatar}
                          alt={article.author.name}
                          className="h-8 w-8 rounded-full"
                        />
                        <span className="text-sm text-gray-600">{article.author.name}</span>
                      </div>
                      <span className="inline-flex items-center text-[#B6AC7D] font-medium">
                        Read More
                        <ArrowUpRight className="ml-1 h-4 w-4" />
                      </span>
                    </div>
                  </div>
                </article>
              </Link>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Latest;