import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { 
  FileText, Edit, Eye, Trash2, Plus, 
  Search, Calendar, MessageSquare, Heart,
  Settings, Users, Ban, CheckCircle2, Shield,
  BarChart2, TrendingUp, Clock, Filter,
  Crown
} from 'lucide-react';
import { supabase } from '../lib/supabase';
import { useAuth } from '../contexts/AuthContext';
import { format } from 'date-fns';

const Admin = () => {
  const { user, isAdmin } = useAuth();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('articles');
  const [articles, setArticles] = useState<any[]>([]);
  const [users, setUsers] = useState<any[]>([]);
  const [comments, setComments] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [userFilter, setUserFilter] = useState('all');

  useEffect(() => {
    const checkAccess = async () => {
      try {
        if (!user) {
          navigate('/auth');
          return;
        }

        if (!isAdmin) {
          navigate('/');
          return;
        }

        fetchData();
      } catch (error) {
        console.error('Error checking admin access:', error);
        setError('Error checking access. Please try again.');
        navigate('/');
      }
    };

    checkAccess();
  }, [user, isAdmin, activeTab]);

  const fetchData = async () => {
    try {
      setLoading(true);
      setError(null);

      switch (activeTab) {
        case 'articles':
          const { data: articles, error: articlesError } = await supabase
            .from('articles')
            .select(`
              *,
              author:profiles(username),
              comments:comments(count),
              likes:likes(count),
              views:views(count)
            `);

          if (articlesError) throw articlesError;
          setArticles(articles || []);
          break;

        case 'users':
          const { data: users, error: usersError } = await supabase
            .from('profiles')
            .select('*');

          if (usersError) throw usersError;
          setUsers(users || []);
          break;

        case 'comments':
          const { data: comments, error: commentsError } = await supabase
            .from('comments')
            .select(`
              *,
              user:profiles(username),
              article:articles(title)
            `);

          if (commentsError) throw commentsError;
          setComments(comments || []);
          break;
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setError('Error loading data. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateUserRole = async (userId: string, newRole: string) => {
    try {
      const { error } = await supabase
        .from('profiles')
        .update({ role: newRole })
        .eq('id', userId);

      if (error) throw error;

      setUsers(users.map(user => 
        user.id === userId ? { ...user, role: newRole } : user
      ));
    } catch (error) {
      console.error('Error updating user role:', error);
      setError('Failed to update user role. Please try again.');
    }
  };

  const handleToggleUserStatus = async (userId: string, isBlocked: boolean) => {
    try {
      const { error } = await supabase
        .from('profiles')
        .update({ is_blocked: !isBlocked })
        .eq('id', userId);

      if (error) throw error;

      setUsers(users.map(user => 
        user.id === userId ? { ...user, is_blocked: !isBlocked } : user
      ));
    } catch (error) {
      console.error('Error toggling user status:', error);
      setError('Failed to update user status. Please try again.');
    }
  };

  const handleDeleteArticle = async (id: string) => {
    if (!confirm('Are you sure you want to delete this article? This action cannot be undone.')) return;

    try {
      setLoading(true);
      
      // Delete the article - cascading delete will handle related records
      const { error } = await supabase
        .from('articles')
        .delete()
        .eq('id', id);

      if (error) throw error;

      // Update local state to remove the deleted article
      setArticles(articles.filter(article => article.id !== id));
      
      // Show success message
      setError('Article successfully deleted');
      setTimeout(() => setError(null), 3000);
    } catch (error) {
      console.error('Error deleting article:', error);
      setError('Failed to delete article. Please try again.');
      setTimeout(() => setError(null), 3000);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteComment = async (id: string) => {
    if (!confirm('Are you sure you want to delete this comment?')) return;

    try {
      const { error } = await supabase
        .from('comments')
        .delete()
        .eq('id', id);

      if (error) throw error;

      setComments(comments.filter(comment => comment.id !== id));
    } catch (error) {
      console.error('Error deleting comment:', error);
      setError('Failed to delete comment. Please try again.');
    }
  };

  const filteredUsers = users.filter(user => {
    if (userFilter !== 'all' && user.role !== userFilter) return false;
    return user.username?.toLowerCase().includes(searchQuery.toLowerCase()) ||
           user.email?.toLowerCase().includes(searchQuery.toLowerCase());
  });

  const stripHtml = (html: string): string => {
    const tmp = document.createElement('div');
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || '';
  };

  const truncateExcerpt = (text: string): string => {
    const plainText = stripHtml(text);
    return plainText.length > 100 ? plainText.substring(0, 100).trim() + '...' : plainText;
  };

  return (
    <div className="min-h-screen bg-gray-50 pt-24">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {error && (
          <div className="mb-6 bg-red-50 border border-red-200 text-red-600 px-4 py-3 rounded-lg">
            {error}
          </div>
        )}

        <div className="flex flex-col md:flex-row md:space-x-8">
          {/* Sidebar */}
          <div className="w-full md:w-64 mb-8 md:mb-0">
            <div className="bg-white rounded-lg shadow overflow-hidden">
              <div className="p-6">
                <h2 className="text-lg font-medium text-gray-900">Admin Dashboard</h2>
                <p className="mt-1 text-sm text-gray-600">Manage your platform</p>
              </div>
              <nav className="space-y-1">
                <button
                  onClick={() => setActiveTab('articles')}
                  className={`w-full flex items-center px-6 py-3 text-sm font-medium ${
                    activeTab === 'articles'
                      ? 'bg-[#B6AC7D] text-white'
                      : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900'
                  }`}
                >
                  <FileText className="h-5 w-5 mr-3" />
                  Articles
                </button>
                <button
                  onClick={() => setActiveTab('users')}
                  className={`w-full flex items-center px-6 py-3 text-sm font-medium ${
                    activeTab === 'users'
                      ? 'bg-[#B6AC7D] text-white'
                      : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900'
                  }`}
                >
                  <Users className="h-5 w-5 mr-3" />
                  Users
                </button>
                <button
                  onClick={() => setActiveTab('comments')}
                  className={`w-full flex items-center px-6 py-3 text-sm font-medium ${
                    activeTab === 'comments'
                      ? 'bg-[#B6AC7D] text-white'
                      : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900'
                  }`}
                >
                  <MessageSquare className="h-5 w-5 mr-3" />
                  Comments
                </button>
                <Link
                  to="/admin/settings"
                  className="w-full flex items-center px-6 py-3 text-sm font-medium text-gray-600 hover:bg-gray-50 hover:text-gray-900"
                >
                  <Settings className="h-5 w-5 mr-3" />
                  Settings
                </Link>
              </nav>
            </div>
          </div>

          {/* Main Content */}
          <div className="flex-1">
            {loading ? (
              <div className="bg-white rounded-lg shadow p-6">
                <div className="animate-pulse space-y-4">
                  <div className="h-4 bg-gray-200 rounded w-1/4"></div>
                  <div className="space-y-3">
                    <div className="h-4 bg-gray-200 rounded"></div>
                    <div className="h-4 bg-gray-200 rounded w-5/6"></div>
                  </div>
                </div>
              </div>
            ) : (
              <>
                {activeTab === 'articles' && (
                  <div className="bg-white rounded-lg shadow">
                    <div className="p-6 border-b border-gray-200">
                      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4">
                        <div className="relative">
                          <input
                            type="text"
                            placeholder="Search articles..."
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            className="w-full sm:w-64 pl-10 pr-4 py-2 rounded-lg border focus:ring-2 focus:ring-[#B6AC7D]"
                          />
                          <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
                        </div>
                        <button
                          onClick={() => navigate('/admin/new-article')}
                          className="flex items-center px-4 py-2 bg-[#B6AC7D] text-white rounded-lg hover:bg-[#A49C6D] whitespace-nowrap"
                        >
                          <Plus className="h-5 w-5 mr-2" />
                          New Article
                        </button>
                      </div>
                    </div>

                    <div className="divide-y divide-gray-200">
                      {articles
                        .filter(article => 
                          article.title.toLowerCase().includes(searchQuery.toLowerCase())
                        )
                        .map(article => (
                          <div key={article.id} className="p-6 hover:bg-gray-50">
                            <div className="flex flex-col sm:flex-row items-start sm:items-center gap-6">
                              <img
                                src={article.image_url || 'https://via.placeholder.com/150'}
                                alt={article.title}
                                className="w-full sm:w-32 h-32 object-cover rounded-lg"
                              />
                              <div className="flex-1 min-w-0">
                                <h3 className="text-lg font-medium text-gray-900 truncate mb-1">
                                  {article.title}
                                </h3>
                                <p className="text-sm text-gray-500 mb-2 line-clamp-2">
                                  {truncateExcerpt(article.excerpt || article.content)}
                                </p>
                                <div className="flex flex-wrap items-center gap-4 text-sm text-gray-500">
                                  <span className="flex items-center">
                                    <Calendar className="h-4 w-4 mr-1" />
                                    {format(new Date(article.created_at), 'MMM d, yyyy')}
                                  </span>
                                  <span className="flex items-center">
                                    <Eye className="h-4 w-4 mr-1" />
                                    {article.views?.[0]?.count || 0} views
                                  </span>
                                  <span className="flex items-center">
                                    <MessageSquare className="h-4 w-4 mr-1" />
                                    {article.comments?.[0]?.count || 0} comments
                                  </span>
                                  <span className="flex items-center">
                                    <Heart className="h-4 w-4 mr-1" />
                                    {article.likes?.[0]?.count || 0} likes
                                  </span>
                                </div>
                              </div>
                              <div className="flex items-center gap-2">
                                <button
                                  onClick={() => navigate(`/admin/edit/${article.id}`)}
                                  className="p-2 text-[#B6AC7D] hover:bg-[#B6AC7D]/10 rounded-full"
                                >
                                  <Edit className="h-5 w-5" />
                                </button>
                                <button
                                  onClick={() => handleDeleteArticle(article.id)}
                                  className="p-2 text-red-600 hover:bg-red-50 rounded-full"
                                >
                                  <Trash2 className="h-5 w-5" />
                                </button>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                )}

                {activeTab === 'users' && (
                  <>
                    <div className="p-6 border-b border-gray-200">
                      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4">
                        <div className="flex items-center gap-4">
                          <div className="relative">
                            <input
                              type="text"
                              placeholder="Search users..."
                              value={searchQuery}
                              onChange={(e) => setSearchQuery(e.target.value)}
                              className="w-full sm:w-64 pl-10 pr-4 py-2 rounded-lg border focus:ring-2 focus:ring-[#B6AC7D]"
                            />
                            <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
                          </div>
                          <select
                            value={userFilter}
                            onChange={(e) => setUserFilter(e.target.value)}
                            className="pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-[#B6AC7D] focus:border-[#B6AC7D] rounded-lg"
                          >
                            <option value="all">All Roles</option>
                            <option value="admin">Admins</option>
                            <option value="writer">Writers</option>
                            <option value="premium">Premium Users</option>
                            <option value="user">Regular Users</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="overflow-x-auto">
                      <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                          <tr>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              User
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Role
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Joined
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Status
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Actions
                            </th>
                          </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                          {filteredUsers.map((user) => (
                            <tr key={user.id} className="hover:bg-gray-50">
                              <td className="px-6 py-4">
                                <div className="flex items-center">
                                  <img
                                    src={user.avatar_url || 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80'}
                                    alt={user.username}
                                    className="h-8 w-8 rounded-full"
                                  />
                                  <div className="ml-4">
                                    <div className="text-sm font-medium text-gray-900">
                                      {user.username}
                                    </div>
                                    <div className="text-sm text-gray-500">
                                      {user.email}
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                <select
                                  value={user.role}
                                  onChange={(e) => handleUpdateUserRole(user.id, e.target.value)}
                                  className="text-sm rounded-md border-gray-300 focus:ring-[#B6AC7D] focus:border-[#B6AC7D]"
                                >
                                  <option value="user">Regular User</option>
                                  <option value="premium">Premium User</option>
                                  <option value="writer">Writer</option>
                                  <option value="admin">Admin</option>
                                </select>
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                <div className="text-sm text-gray-500">
                                  {format(new Date(user.created_at), 'MMM d, yyyy')}
                                </div>
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                <span className={`px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full ${
                                  user.is_blocked
                                    ? 'bg-red-100 text-red-800'
                                    : 'bg-green-100 text-green-800'
                                }`}>
                                  {user.is_blocked ? 'Blocked' : 'Active'}
                                </span>
                                {user.role === 'premium' && (
                                  <span className="ml-2 px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">
                                    <Crown className="h-3 w-3 mr-1" />
                                    Premium
                                  </span>
                                )}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                <button
                                  onClick={() => handleToggleUserStatus(user.id, user.is_blocked)}
                                  className={`inline-flex items-center px-3 py-1 rounded-md ${
                                    user.is_blocked
                                      ? 'bg-green-100 text-green-700 hover:bg-green-200'
                                      : 'bg-red-100 text-red-700 hover:bg-red-200'
                                  }`}
                                >
                                  {user.is_blocked ? (
                                    <>
                                      <CheckCircle2 className="h-4 w-4 mr-1" />
                                      Unblock
                                    </>
                                  ) : (
                                    <>
                                      <Ban className="h-4 w-4 mr-1" />
                                      Block
                                    </>
                                  )}
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </>
                )}

                {activeTab === 'comments' && (
                  <div className="bg-white rounded-lg shadow">
                    <div className="p-6 border-b border-gray-200">
                      <div className="relative">
                        <input
                          type="text"
                          placeholder="Search comments..."
                          value={searchQuery}
                          onChange={(e) => setSearchQuery(e.target.value)}
                          className="w-full sm:w-64 pl-10 pr-4 py-2 rounded-lg border focus:ring-2 focus:ring-[#B6AC7D]"
                        />
                        <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
                      </div>
                    </div>

                    <div className="divide-y divide-gray-200">
                      {comments
                        .filter(comment => 
                          comment.content.toLowerCase().includes(searchQuery.toLowerCase())
                        )
                        .map(comment => (
                          <div key={comment.id} className="p-6 hover:bg-gray-50">
                            <div className="flex items-start justify-between">
                              <div className="flex-1">
                                <div className="flex items-center mb-2">
                                  <span className="text-sm font-medium text-gray-900">
                                    {comment.user?.username || 'Anonymous'}
                                  </span>
                                  <span className="mx-2 text-gray-500">•</span>
                                  <span className="text-sm text-gray-500">
                                    {format(new Date(comment.created_at), 'MMM d, yyyy')}
                                  </span>
                                </div>
                                <p className="text-gray-900 mb-2">{comment.content}</p>
                                <p className="text-sm text-gray-500">
                                  On article: {comment.article?.title}
                                </p>
                              </div>
                              <button
                                onClick={() => handleDeleteComment(comment.id)}
                                className="ml-4 text-red-600 hover:text-red-900"
                              >
                                <Trash2 className="h-5 w-5" />
                              </button>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Admin;