import React, { useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { Heart, Reply, MessageSquare } from 'lucide-react';

interface Comment {
  id: string;
  content: string;
  author: {
    name: string;
    avatar: string;
  };
  createdAt: string;
  likes: number;
  isLiked: boolean;
  replies: Comment[];
}

interface CommentSectionProps {
  comments: Comment[];
  onAddComment: (content: string, parentId?: string) => Promise<void>;
  onLikeComment: (commentId: string) => Promise<void>;
}

const CommentSection: React.FC<CommentSectionProps> = ({
  comments,
  onAddComment,
  onLikeComment,
}) => {
  const { user } = useAuth();
  const [newComment, setNewComment] = useState('');
  const [replyingTo, setReplyingTo] = useState<string | null>(null);

  const handleSubmit = async (e: React.FormEvent, parentId?: string) => {
    e.preventDefault();
    if (!newComment.trim()) return;

    await onAddComment(newComment, parentId);
    setNewComment('');
    setReplyingTo(null);
  };

  const CommentComponent = ({ comment, depth = 0 }: { comment: Comment; depth?: number }) => (
    <div className={`${depth > 0 ? 'ml-8' : ''} mb-8`}>
      <div className="flex space-x-4">
        <img
          src={comment.author.avatar}
          alt={comment.author.name}
          className="w-10 h-10 rounded-full"
        />
        <div className="flex-1">
          <div className="bg-gray-50 rounded-lg p-4">
            <div className="flex items-center justify-between mb-2">
              <span className="font-medium text-gray-900">
                {comment.author.name}
              </span>
              <span className="text-sm text-gray-500">
                {comment.createdAt}
              </span>
            </div>
            <p className="text-gray-700">{comment.content}</p>
          </div>
          <div className="flex items-center space-x-4 mt-2">
            <button
              onClick={() => onLikeComment(comment.id)}
              className={`flex items-center space-x-1 ${
                comment.isLiked ? 'text-[#B6AC7D]' : 'text-gray-500 hover:text-[#B6AC7D]'
              } transition-colors`}
            >
              <Heart className={`h-4 w-4 ${comment.isLiked ? 'fill-current' : ''}`} />
              <span>{comment.likes}</span>
            </button>
            {user && (
              <button
                onClick={() => setReplyingTo(comment.id)}
                className="flex items-center space-x-1 text-gray-500 hover:text-[#B6AC7D] transition-colors"
              >
                <Reply className="h-4 w-4" />
                <span>Reply</span>
              </button>
            )}
          </div>
          {replyingTo === comment.id && (
            <form onSubmit={(e) => handleSubmit(e, comment.id)} className="mt-4">
              <textarea
                value={newComment}
                onChange={(e) => setNewComment(e.target.value)}
                className="w-full p-4 border rounded-lg focus:ring-2 focus:ring-[#B6AC7D] focus:border-transparent resize-none"
                placeholder="Write a reply..."
                rows={3}
              />
              <div className="flex justify-end space-x-2 mt-2">
                <button
                  type="button"
                  onClick={() => setReplyingTo(null)}
                  className="px-4 py-2 text-gray-600 hover:text-gray-900"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-6 py-2 bg-[#B6AC7D] text-white rounded-lg hover:bg-[#A49C6D] transition-colors"
                >
                  Reply
                </button>
              </div>
            </form>
          )}
          {comment.replies?.map((reply) => (
            <CommentComponent key={reply.id} comment={reply} depth={depth + 1} />
          ))}
        </div>
      </div>
    </div>
  );

  return (
    <div>
      {user ? (
        <form onSubmit={(e) => handleSubmit(e)} className="mb-12">
          <div className="flex items-start space-x-4">
            <img
              src={user.user_metadata?.avatar_url || 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80'}
              alt={user.email || ''}
              className="w-10 h-10 rounded-full"
            />
            <div className="flex-1">
              <textarea
                value={newComment}
                onChange={(e) => setNewComment(e.target.value)}
                className="w-full p-4 border rounded-lg focus:ring-2 focus:ring-[#B6AC7D] focus:border-transparent resize-none"
                placeholder="Share your thoughts..."
                rows={4}
              />
              <div className="flex justify-between items-center mt-2">
                <div className="flex items-center text-sm text-gray-500">
                  <MessageSquare className="h-4 w-4 mr-1" />
                  <span>Be respectful and constructive</span>
                </div>
                <button
                  type="submit"
                  className="px-6 py-2 bg-[#B6AC7D] text-white rounded-lg hover:bg-[#A49C6D] transition-colors"
                >
                  Post Comment
                </button>
              </div>
            </div>
          </div>
        </form>
      ) : (
        <div className="bg-gray-50 rounded-lg p-6 text-center mb-12">
          <h3 className="text-lg font-medium text-gray-900 mb-2">Join the Discussion</h3>
          <p className="text-gray-600 mb-4">
            Sign in to share your thoughts and engage with our community.
          </p>
          <a
            href="/auth"
            className="inline-flex items-center px-6 py-2 bg-[#B6AC7D] text-white rounded-lg hover:bg-[#A49C6D] transition-colors"
          >
            Sign In to Comment
          </a>
        </div>
      )}

      <div className="space-y-8">
        {comments.length === 0 ? (
          <div className="text-center py-12">
            <MessageSquare className="h-12 w-12 text-gray-400 mx-auto mb-4" />
            <h3 className="text-lg font-medium text-gray-900 mb-2">No comments yet</h3>
            <p className="text-gray-600">Be the first to share your thoughts!</p>
          </div>
        ) : (
          comments.map((comment) => (
            <CommentComponent key={comment.id} comment={comment} />
          ))
        )}
      </div>
    </div>
  );
};

export default CommentSection;