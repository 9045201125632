import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  FileText, Edit, Eye, Trash2, Plus, 
  Search, Calendar, MessageSquare, Heart
} from 'lucide-react';
import { supabase } from '../lib/supabase';
import { useAuth } from '../contexts/AuthContext';
import { format } from 'date-fns';

const WriterDashboard = () => {
  const { user, profile } = useAuth();
  const navigate = useNavigate();
  const [articles, setArticles] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [stats, setStats] = useState({
    totalArticles: 0,
    totalViews: 0,
    totalComments: 0,
    totalLikes: 0
  });

  useEffect(() => {
    const checkAccess = async () => {
      try {
        if (!user || !profile) {
          navigate('/auth');
          return;
        }

        if (profile.role !== 'writer' && profile.role !== 'admin') {
          console.error('Access denied: Not a writer or admin');
          navigate('/');
          return;
        }

        fetchData();
      } catch (error) {
        console.error('Error checking writer access:', error);
        setError('Error checking access. Please try again.');
        navigate('/');
      }
    };

    checkAccess();
  }, [user, profile]);

  const fetchData = async () => {
    if (!user) return;

    try {
      setLoading(true);
      const { data: articles, error: articlesError } = await supabase
        .from('articles')
        .select(`
          *,
          views(count),
          likes(count),
          comments(count)
        `)
        .eq('author_id', user.id)
        .order('created_at', { ascending: false });

      if (articlesError) throw articlesError;

      if (articles) {
        setArticles(articles);
        
        // Calculate stats
        const stats = articles.reduce((acc, article) => ({
          totalArticles: acc.totalArticles + 1,
          totalViews: acc.totalViews + (article.views?.[0]?.count || 0),
          totalComments: acc.totalComments + (article.comments?.[0]?.count || 0),
          totalLikes: acc.totalLikes + (article.likes?.[0]?.count || 0)
        }), {
          totalArticles: 0,
          totalViews: 0,
          totalComments: 0,
          totalLikes: 0
        });

        setStats(stats);
      }
    } catch (error) {
      console.error('Error fetching articles:', error);
      setError('Error loading articles. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteArticle = async (id: string) => {
    if (!confirm('Are you sure you want to delete this article?')) return;

    const { error } = await supabase
      .from('articles')
      .delete()
      .eq('id', id);

    if (!error) {
      await fetchData();
    }
  };

  const stripHtml = (html: string): string => {
    const tmp = document.createElement('div');
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || '';
  };

  const truncateExcerpt = (text: string): string => {
    const plainText = stripHtml(text);
    return plainText.length > 100 ? plainText.substring(0, 100).trim() + '...' : plainText;
  };

  return (
    <div className="min-h-screen bg-gray-50 pt-24">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {error && (
          <div className="mb-6 bg-red-50 border border-red-200 text-red-600 px-4 py-3 rounded-lg">
            {error}
          </div>
        )}

        {/* Writer Profile */}
        <div className="bg-white rounded-lg shadow-sm p-6 mb-8">
          <div className="flex items-center space-x-4">
            <img
              src={profile?.avatar_url || 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80'}
              alt={profile?.username || 'Profile'}
              className="w-16 h-16 rounded-full"
            />
            <div>
              <h2 className="text-xl font-bold text-gray-900">
                {profile?.username || 'Anonymous'}
              </h2>
              <p className="text-gray-500">{profile?.bio || 'No bio available'}</p>
            </div>
          </div>
        </div>

        {/* Stats Overview */}
        <div className="grid grid-cols-1 md:grid-cols-4 gap-6 mb-8">
          <div className="bg-white rounded-lg shadow p-6">
            <div className="flex items-center">
              <div className="p-3 rounded-full bg-blue-100 text-blue-600">
                <FileText className="h-6 w-6" />
              </div>
              <div className="ml-4">
                <p className="text-sm font-medium text-gray-500">Total Articles</p>
                <h3 className="text-lg font-semibold text-gray-900">{stats.totalArticles}</h3>
              </div>
            </div>
          </div>

          <div className="bg-white rounded-lg shadow p-6">
            <div className="flex items-center">
              <div className="p-3 rounded-full bg-green-100 text-green-600">
                <Eye className="h-6 w-6" />
              </div>
              <div className="ml-4">
                <p className="text-sm font-medium text-gray-500">Total Views</p>
                <h3 className="text-lg font-semibold text-gray-900">{stats.totalViews}</h3>
              </div>
            </div>
          </div>

          <div className="bg-white rounded-lg shadow p-6">
            <div className="flex items-center">
              <div className="p-3 rounded-full bg-purple-100 text-purple-600">
                <MessageSquare className="h-6 w-6" />
              </div>
              <div className="ml-4">
                <p className="text-sm font-medium text-gray-500">Total Comments</p>
                <h3 className="text-lg font-semibold text-gray-900">{stats.totalComments}</h3>
              </div>
            </div>
          </div>

          <div className="bg-white rounded-lg shadow p-6">
            <div className="flex items-center">
              <div className="p-3 rounded-full bg-red-100 text-red-600">
                <Heart className="h-6 w-6" />
              </div>
              <div className="ml-4">
                <p className="text-sm font-medium text-gray-500">Total Likes</p>
                <h3 className="text-lg font-semibold text-gray-900">{stats.totalLikes}</h3>
              </div>
            </div>
          </div>
        </div>

        {/* Content Management */}
        <div className="bg-white rounded-lg shadow">
          <div className="p-6 border-b border-gray-200">
            <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4">
              <h2 className="text-lg font-medium text-gray-900">Your Articles</h2>
              <div className="flex items-center gap-4">
                <div className="relative">
                  <input
                    type="text"
                    placeholder="Search articles..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="w-full sm:w-64 pl-10 pr-4 py-2 rounded-lg border focus:ring-2 focus:ring-[#B6AC7D]"
                  />
                  <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
                </div>
                <button
                  onClick={() => navigate('/writer/new-article')}
                  className="flex items-center px-4 py-2 bg-[#B6AC7D] text-white rounded-lg hover:bg-[#A49C6D] whitespace-nowrap"
                >
                  <Plus className="h-5 w-5 mr-2" />
                  New Article
                </button>
              </div>
            </div>
          </div>

          <div className="divide-y divide-gray-200">
            {loading ? (
              <div className="p-6">
                <div className="animate-pulse space-y-4">
                  {[1, 2, 3].map((n) => (
                    <div key={n} className="flex items-center space-x-4">
                      <div className="w-16 h-16 bg-gray-200 rounded"></div>
                      <div className="flex-1 space-y-2">
                        <div className="h-4 bg-gray-200 rounded w-3/4"></div>
                        <div className="h-4 bg-gray-200 rounded w-1/2"></div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : articles.length === 0 ? (
              <div className="p-6 text-center">
                <FileText className="mx-auto h-12 w-12 text-gray-400" />
                <h3 className="mt-2 text-sm font-medium text-gray-900">No articles yet</h3>
                <p className="mt-1 text-sm text-gray-500">
                  Get started by creating your first article
                </p>
              </div>
            ) : (
              articles
                .filter(article => 
                  article.title.toLowerCase().includes(searchQuery.toLowerCase())
                )
                .map(article => (
                  <div key={article.id} className="p-6 hover:bg-gray-50">
                    <div className="flex flex-col sm:flex-row items-start sm:items-center gap-6">
                      <img
                        src={article.image_url || 'https://via.placeholder.com/150'}
                        alt={article.title}
                        className="w-full sm:w-32 h-32 object-cover rounded-lg"
                      />
                      <div className="flex-1 min-w-0">
                        <h3 className="text-lg font-medium text-gray-900 truncate mb-1">
                          {article.title}
                        </h3>
                        <p className="text-sm text-gray-500 mb-2 line-clamp-2">
                          {truncateExcerpt(article.excerpt || article.content)}
                        </p>
                        <div className="flex flex-wrap items-center gap-4 text-sm text-gray-500">
                          <span className="flex items-center">
                            <Calendar className="h-4 w-4 mr-1" />
                            {format(new Date(article.created_at), 'MMM d, yyyy')}
                          </span>
                          <span className="flex items-center">
                            <Eye className="h-4 w-4 mr-1" />
                            {article.views?.[0]?.count || 0} views
                          </span>
                          <span className="flex items-center">
                            <MessageSquare className="h-4 w-4 mr-1" />
                            {article.comments?.[0]?.count || 0} comments
                          </span>
                          <span className="flex items-center">
                            <Heart className="h-4 w-4 mr-1" />
                            {article.likes?.[0]?.count || 0} likes
                          </span>
                        </div>
                      </div>
                      <div className="flex items-center gap-2">
                        <button
                          onClick={() => navigate(`/writer/edit/${article.id}`)}
                          className="p-2 text-[#B6AC7D] hover:bg-[#B6AC7D]/10 rounded-full"
                        >
                          <Edit className="h-5 w-5" />
                        </button>
                        <button
                          onClick={() => handleDeleteArticle(article.id)}
                          className="p-2 text-red-600 hover:bg-red-50 rounded-full"
                        >
                          <Trash2 className="h-5 w-5" />
                        </button>
                      </div>
                    </div>
                  </div>
                ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WriterDashboard;