import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { supabase } from '../lib/supabase';
import ArticleCard from '../components/ArticleCard';
import { Search, Filter, ChevronDown } from 'lucide-react';
import { marked } from 'marked';

interface Article {
  id: string;
  slug: string;
  title: string;
  excerpt: string;
  imageUrl: string;
  author: {
    name: string;
    avatar: string;
  };
  createdAt: string;
  stats: {
    viewCount: number;
    likeCount: number;
    commentCount: number;
  };
}

const AllArticles = () => {
  const [searchParams] = useSearchParams();
  const initialSearch = searchParams.get('search') || '';
  const [articles, setArticles] = useState<Article[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState(initialSearch);
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [sortBy, setSortBy] = useState('latest');

  useEffect(() => {
    fetchArticles();
  }, [selectedCategory, sortBy, searchQuery]);

  const stripHtml = (html: string): string => {
    const tmp = document.createElement('div');
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || '';
  };

  const fetchArticles = async () => {
    try {
      setLoading(true);
      
      let query = supabase
        .from('articles')
        .select(`
          *,
          author:profiles(username, avatar_url),
          views(count),
          likes(count),
          comments(count)
        `)
        .eq('status', 'published');

      // Apply category filter
      if (selectedCategory !== 'all') {
        query = query.eq('category', selectedCategory);
      }

      // Apply search if present
      if (searchQuery) {
        query = query.or(`title.ilike.%${searchQuery}%,content.fts.${searchQuery}`);
      }

      // Apply sorting
      switch (sortBy) {
        case 'popular':
          query = query.order('views_count', { ascending: false });
          break;
        case 'discussed':
          query = query.order('comments_count', { ascending: false });
          break;
        case 'oldest':
          query = query.order('created_at', { ascending: true });
          break;
        case 'latest':
        default:
          query = query.order('created_at', { ascending: false });
      }

      const { data: articles, error } = await query;

      if (error) throw error;

      if (articles) {
        const transformedArticles = articles.map(article => {
          // Convert markdown to plain text for excerpt
          const htmlContent = marked(article.content || '');
          const plainText = stripHtml(htmlContent);
          
          // If searching, find relevant excerpt
          let excerpt = '';
          if (searchQuery) {
            const searchTermIndex = plainText.toLowerCase().indexOf(searchQuery.toLowerCase());
            if (searchTermIndex >= 0) {
              const start = Math.max(0, searchTermIndex - 50);
              const end = Math.min(plainText.length, searchTermIndex + searchQuery.length + 50);
              excerpt = '...' + plainText.slice(start, end) + '...';
            } else {
              excerpt = plainText.substring(0, 150) + '...';
            }
          } else {
            excerpt = plainText.substring(0, 150) + '...';
          }

          return {
            id: article.id,
            slug: article.slug,
            title: article.title,
            excerpt,
            imageUrl: article.image_url,
            author: {
              name: article.author?.username || 'Anonymous',
              avatar: article.author?.avatar_url || 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e',
            },
            createdAt: article.created_at,
            stats: {
              viewCount: article.views?.[0]?.count || 0,
              likeCount: article.likes?.[0]?.count || 0,
              commentCount: article.comments?.[0]?.count || 0,
            },
          };
        });

        setArticles(transformedArticles);
      }
    } catch (error) {
      console.error('Error fetching articles:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (e: React.FormEvent) => {
    e.preventDefault();
    fetchArticles();
  };

  return (
    <div className="min-h-screen bg-gray-50 pt-24">
      {/* Header */}
      <div className="text-center mb-12">
        <h1 className="text-4xl font-bold text-gray-900 mb-4">All Articles</h1>
        <p className="text-lg text-gray-600">Discover our collection of thought-provoking stories</p>
      </div>

      {/* Search Form */}
      <div className="bg-white rounded-lg shadow-sm p-6 mb-8">
        <form onSubmit={handleSearch} className="flex flex-col md:flex-row md:items-center md:space-x-6 space-y-4 md:space-y-0">
          <div className="flex-1 relative">
            <input
              type="text"
              placeholder="Search articles..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="w-full pl-10 pr-4 py-2 rounded-lg border focus:ring-2 focus:ring-[#B6AC7D] focus:border-transparent"
            />
            <button type="submit" className="absolute left-3 top-2.5">
              <Search className="h-5 w-5 text-gray-400" />
            </button>
          </div>

          {/* Sort */}
          <div className="flex items-center space-x-2">
            <Filter className="h-5 w-5 text-gray-400" />
            <select
              value={sortBy}
              onChange={(e) => setSortBy(e.target.value)}
              className="pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-[#B6AC7D] focus:border-[#B6AC7D] rounded-lg"
            >
              <option value="latest">Latest</option>
              <option value="popular">Most Popular</option>
              <option value="discussed">Most Discussed</option>
              <option value="oldest">Oldest</option>
            </select>
          </div>
        </form>
      </div>

      {/* Articles Grid */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {loading ? (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {[1, 2, 3, 4, 5, 6].map((n) => (
              <div key={n} className="animate-pulse">
                <div className="bg-gray-200 h-64 rounded-lg mb-4"></div>
                <div className="h-4 bg-gray-200 rounded w-3/4 mb-2"></div>
                <div className="h-4 bg-gray-200 rounded w-1/2"></div>
              </div>
            ))}
          </div>
        ) : (
          <>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {articles.map((article) => (
                <ArticleCard key={article.id} article={article} />
              ))}
            </div>

            {articles.length === 0 && (
              <div className="text-center py-12">
                <h3 className="text-lg font-medium text-gray-900 mb-2">No articles found</h3>
                <p className="text-gray-600">Try adjusting your search criteria</p>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default AllArticles;