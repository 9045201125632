import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Bold, Italic, List, Link as LinkIcon, Image as ImageIcon,
  Type, Clock, Save, Eye, Settings, X, ChevronDown,
  BookOpen, Layout, Sparkles, Hash, Heading1, Heading2,
  Quote, Code, ListOrdered, FileImage, Undo, Redo,
  Upload, ExternalLink, ArrowLeft
} from 'lucide-react';
import { useDebounce } from '../hooks/useDebounce';
import { marked } from 'marked';

interface WritingEditorProps {
  initialContent?: string;
  initialTitle?: string;
  initialCoverImage?: string;
  initialCategory?: string;
  initialTags?: string[];
  onSave: (content: string, title: string, metadata: any, publish?: boolean) => Promise<void>;
  onImageUpload?: (file: File) => Promise<string>;
  isDraft?: boolean;
  setIsDraft?: (isDraft: boolean) => void;
}

const WritingEditor: React.FC<WritingEditorProps> = ({
  initialContent = '',
  initialTitle = '',
  initialCoverImage = '',
  initialCategory = '',
  initialTags = [],
  onSave,
  onImageUpload,
  isDraft = true,
  setIsDraft
}) => {
  const navigate = useNavigate();
  const [content, setContent] = useState(initialContent);
  const [title, setTitle] = useState(initialTitle);
  const [coverImage, setCoverImage] = useState(initialCoverImage);
  const [selectedCategory, setSelectedCategory] = useState(initialCategory);
  const [selectedTags, setSelectedTags] = useState<string[]>(initialTags);
  const [showCategoryModal, setShowCategoryModal] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [showToolbar, setShowToolbar] = useState(true);
  const [wordCount, setWordCount] = useState(0);
  const [readingTime, setReadingTime] = useState(0);
  const [isSaving, setIsSaving] = useState(false);
  const [lastSaved, setLastSaved] = useState<Date | null>(null);
  const [showSettings, setShowSettings] = useState(false);
  const [showEmbedModal, setShowEmbedModal] = useState(false);
  const [embedUrl, setEmbedUrl] = useState('');
  const [error, setError] = useState<string | null>(null);

  const editorRef = useRef<HTMLDivElement>(null);
  const debouncedContent = useDebounce(content, 1000);
  const debouncedTitle = useDebounce(title, 1000);

  const categories = [
    { id: 'world-news', name: 'World News', description: 'World coverage of major events shaping our world today.' },
    { id: 'faith', name: 'Faith', description: 'Inspiring news stories rooted in faith and eternal truth.' },
    { id: 'lifestyle', name: 'Lifestyle', description: 'Insights on health, wellness, family, and daily living.' },
    { id: 'business', name: 'Business', description: 'Economic trends, markets, entrepreneurship, and business news.' },
    { id: 'entertainment', name: 'Entertainment', description: 'Stories at the crossroads of faith, arts, culture, and media.' },
    { id: 'sports', name: 'Sports', description: 'Highlights, analysis, and inspiring stories from the world of sports.' },
    { id: 'tech', name: 'Tech', description: 'Cutting-edge technological innovations and digital trends.' },
    { id: 'science', name: 'Science', description: 'Breakthrough discoveries, environmental news, stewarding God\'s Creation.' },
    { id: 'investigations', name: 'Investigations', description: 'In-depth investigations and exposés uncovering The Truth.' },
    { id: 'voices', name: 'Voices & Views', description: 'Opinion pieces and editorials from an Eternal perspective.' }
  ];

  const tags = [
    // World News tags
    { id: 'us-news', name: 'U.S. News', category: 'world-news' },
    { id: 'worldwide-faith', name: 'Worldwide Faith', category: 'world-news' },
    { id: 'conflicts-crisis', name: 'Conflicts & Crisis', category: 'world-news' },
    { id: 'environment-stewardship', name: 'Environment & Stewardship', category: 'world-news' },
    { id: 'world-affairs', name: 'World Affairs', category: 'world-news' },
    
    // Faith tags
    { id: 'faith-news', name: 'Faith News', category: 'faith' },
    { id: 'inspiration', name: 'Inspiration', category: 'faith' },
    { id: 'prayer-revival', name: 'Prayer & Revival', category: 'faith' },
    
    // Lifestyle tags
    { id: 'family-relationships', name: 'Family & Relationships', category: 'lifestyle' },
    { id: 'health-wellness', name: 'Health & Wellness', category: 'lifestyle' },
    { id: 'food-recipes', name: 'Food & Recipes', category: 'lifestyle' },
    { id: 'style-modesty', name: 'Style & Modesty', category: 'lifestyle' },
    { id: 'travel', name: 'Travel', category: 'lifestyle' },
    
    // Business tags
    { id: 'economy', name: 'Economy', category: 'business' },
    { id: 'personal-finance', name: 'Personal Finance', category: 'business' },
    { id: 'markets-trends', name: 'Markets & Trends', category: 'business' },
    { id: 'faith-based-businesses', name: 'Faith-Based Businesses', category: 'business' },
    { id: 'entrepreneurship', name: 'Entrepreneurship', category: 'business' },
    
    // Entertainment tags
    { id: 'movies', name: 'Movies', category: 'entertainment' },
    { id: 'tv', name: 'TV', category: 'entertainment' },
    { id: 'reviews', name: 'Reviews', category: 'entertainment' },
    { id: 'music', name: 'Music', category: 'entertainment' },
    { id: 'books-literature', name: 'Books & Literature', category: 'entertainment' },
    
    // Sports tags
    { id: 'nfl', name: 'NFL', category: 'sports' },
    { id: 'nba', name: 'NBA', category: 'sports' },
    { id: 'mlb', name: 'MLB', category: 'sports' },
    { id: 'mma', name: 'MMA', category: 'sports' },
    { id: 'boxing', name: 'Boxing', category: 'sports' },
    { id: 'nhl', name: 'NHL', category: 'sports' },
    { id: 'college-sports', name: 'College Sports', category: 'sports' },
    { id: 'youth-sports', name: 'Youth Sports', category: 'sports' },
    
    // Tech tags
    { id: 'innovation-tech-trends', name: 'Innovation & Tech Trends', category: 'tech' },
    { id: 'security', name: 'Security', category: 'tech' },
    { id: 'military-tech', name: 'Military Tech', category: 'tech' },
    
    // Science tags
    { id: 'faith-science', name: 'Faith & Science', category: 'science' },
    { id: 'earth', name: 'Earth', category: 'science' },
    { id: 'climate-stewardship', name: 'Climate & Stewardship', category: 'science' },
    { id: 'national-weather', name: 'National Weather Updates', category: 'science' },
    { id: 'world-weather', name: 'World Weather Updates', category: 'science' },
    { id: 'extreme-events', name: 'Extreme Events', category: 'science' },
    
    // Investigations tags
    { id: 'in-depth-features', name: 'In-Depth Features', category: 'investigations' },
    { id: 'exposing-injustice', name: 'Exposing Injustice', category: 'investigations' },
    { id: 'investigations', name: 'Investigations', category: 'investigations' },
    
    // Voices tags
    { id: 'eternal-perspectives', name: 'Eternal Perspectives', category: 'voices' },
    { id: 'reader-submissions', name: 'Reader Submissions', category: 'voices' },
    { id: 'editorial-columns', name: 'Editorial Columns', category: 'voices' }
  ];

  useEffect(() => {
    if (editorRef.current && initialContent) {
      editorRef.current.innerHTML = initialContent;
      setContent(initialContent);
    }
  }, [initialContent]);

  useEffect(() => {
    if (debouncedContent !== initialContent || debouncedTitle !== initialTitle) {
      handleAutoSave();
    }
  }, [debouncedContent, debouncedTitle]);

  useEffect(() => {
    const words = content.trim().split(/\s+/).length;
    setWordCount(words);
    setReadingTime(Math.ceil(words / 200));
  }, [content]);

  const handleAutoSave = async () => {
    if (!title.trim()) return;

    try {
      setIsSaving(true);
      await onSave(content, title, {
        wordCount,
        readingTime,
        coverImage,
        category: selectedCategory,
        tags: selectedTags,
        lastModified: new Date().toISOString()
      }, false);
      setLastSaved(new Date());
    } catch (error) {
      console.error('Error auto-saving:', error);
      setError(error instanceof Error ? error.message : 'Failed to auto-save');
    } finally {
      setIsSaving(false);
    }
  };

  const handleSave = async (publish: boolean = false) => {
    try {
      if (!title.trim()) {
        throw new Error('Please enter a title for your article');
      }

      if (publish) {
        if (!content.trim()) {
          throw new Error('Please add content before publishing');
        }
        
        if (!coverImage) {
          throw new Error('Please add a cover image before publishing');
        }

        if (!selectedCategory) {
          throw new Error('Please select a category before publishing');
        }
      }

      setIsSaving(true);
      await onSave(content, title, {
        wordCount,
        readingTime,
        coverImage,
        category: selectedCategory,
        tags: selectedTags,
        lastModified: new Date().toISOString()
      }, publish);

      setLastSaved(new Date());
      
      if (publish && setIsDraft) {
        setIsDraft(false);
      }

      setError(null);
    } catch (error) {
      console.error('Error saving:', error);
      setError(error instanceof Error ? error.message : 'Failed to save article');
    } finally {
      setIsSaving(false);
    }
  };

  const handleCoverImageUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file || !onImageUpload) return;

    try {
      const imageUrl = await onImageUpload(file);
      setCoverImage(imageUrl);
      setError(null);
    } catch (error) {
      console.error('Error uploading cover image:', error);
      setError(error instanceof Error ? error.message : 'Failed to upload cover image');
    }
  };

  const handleInlineImageUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file || !onImageUpload) return;

    try {
      const imageUrl = await onImageUpload(file);
      document.execCommand('insertImage', false, imageUrl);
      setError(null);
    } catch (error) {
      console.error('Error uploading image:', error);
      setError(error instanceof Error ? error.message : 'Failed to upload image');
    }
  };

  const handleEmbed = () => {
    if (!embedUrl.trim()) return;

    let embedCode = '';
    
    // YouTube
    if (embedUrl.includes('youtube.com') || embedUrl.includes('youtu.be')) {
      const videoId = embedUrl.includes('youtu.be')
        ? embedUrl.split('/').pop()
        : new URLSearchParams(new URL(embedUrl).search).get('v');
      embedCode = `<iframe width="100%" height="400" src="https://www.youtube.com/embed/${videoId}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
    }
    // Twitter
    else if (embedUrl.includes('twitter.com')) {
      embedCode = `<blockquote class="twitter-tweet"><a href="${embedUrl}"></a></blockquote><script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>`;
    }
    // Default fallback
    else {
      embedCode = `<iframe src="${embedUrl}" width="100%" height="400" frameborder="0"></iframe>`;
    }

    document.execCommand('insertHTML', false, embedCode);
    setEmbedUrl('');
    setShowEmbedModal(false);
  };

  const handleFormat = (command: string, value?: string) => {
    document.execCommand(command, false, value);
    editorRef.current?.focus();
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Tab') {
      e.preventDefault();
      document.execCommand('insertText', false, '    ');
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-[#B6AC7D]/10 via-white to-[#B6AC7D]/5">
      {/* Back Button */}
      <div className="fixed top-20 left-4 z-50">
        <button
          onClick={() => navigate(-1)}
          className="flex items-center text-gray-600 hover:text-gray-900 p-2"
        >
          <ArrowLeft className="h-5 w-5 mr-2" />
          Back
        </button>
      </div>

      {/* Error Message */}
      {error && (
        <div className="fixed top-20 left-1/2 transform -translate-x-1/2 bg-red-50 border border-red-200 text-red-600 px-4 py-3 rounded-lg z-50">
          {error}
        </div>
      )}

      {/* Cover Image Section */}
      <div className="bg-gray-50">
        <div className="max-w-screen-lg mx-auto px-4 py-8">
          <div className="relative">
            {coverImage ? (
              <div className="relative aspect-[21/9] rounded-lg overflow-hidden">
                <img
                  src={coverImage}
                  alt="Article cover"
                  className="w-full h-full object-cover"
                />
                <div className="absolute inset-0 bg-black/40 opacity-0 hover:opacity-100 transition-opacity flex items-center justify-center">
                  <label className="cursor-pointer bg-white/90 text-gray-900 px-4 py-2 rounded-lg hover:bg-white transition-colors">
                    <Upload className="h-4 w-4 inline-block mr-2" />
                    Change Cover
                    <input
                      type="file"
                      className="hidden"
                      accept="image/*"
                      onChange={handleCoverImageUpload}
                    />
                  </label>
                </div>
              </div>
            ) : (
              <label className="block aspect-[21/9] rounded-lg border-2 border-dashed border-gray-300 hover:border-[#B6AC7D] transition-colors cursor-pointer">
                <div className="h-full flex flex-col items-center justify-center">
                  <Upload className="h-8 w-8 text-gray-400 mb-2" />
                  <span className="text-sm text-gray-500">Add Cover Image</span>
                  <input
                    type="file"
                    className="hidden"
                    accept="image/*"
                    onChange={handleCoverImageUpload}
                  />
                </div>
              </label>
            )}
          </div>
        </div>
      </div>

      {/* Main Editor */}
      <main className="pb-20">
        <div className="max-w-screen-lg mx-auto px-4">
          {/* Title */}
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="Enter your article title..."
            className="w-full text-4xl font-bold bg-transparent border-none focus:outline-none focus:ring-0 text-gray-900 placeholder-gray-300 mb-8"
          />

          {/* Editor */}
          <div
            ref={editorRef}
            contentEditable
            onInput={(e) => setContent(e.currentTarget.innerHTML)}
            onKeyDown={handleKeyDown}
            dangerouslySetInnerHTML={{ __html: initialContent }}
            className="prose max-w-none focus:outline-none min-h-[calc(100vh-300px)] text-gray-900 placeholder-gray-300"
          />
        </div>
      </main>

      {/* Toolbar */}
      <div className="fixed bottom-0 left-0 right-0 bg-white border-t border-gray-100">
        <div className="max-w-screen-lg mx-auto px-4">
          {/* Formatting Tools */}
          <div className="flex items-center h-12 space-x-1 border-b border-gray-100">
            <button
              onClick={() => handleFormat('formatBlock', 'h1')}
              className="p-1.5 rounded hover:bg-gray-100 text-gray-600"
              title="Heading 1"
            >
              <Heading1 className="h-4 w-4" />
            </button>
            <button
              onClick={() => handleFormat('formatBlock', 'h2')}
              className="p-1.5 rounded hover:bg-gray-100 text-gray-600"
              title="Heading 2"
            >
              <Heading2 className="h-4 w-4" />
            </button>
            <div className="w-px h-4 bg-gray-200 mx-2" />
            <button
              onClick={() => handleFormat('bold')}
              className="p-1.5 rounded hover:bg-gray-100 text-gray-600"
              title="Bold"
            >
              <Bold className="h-4 w-4" />
            </button>
            <button
              onClick={() => handleFormat('italic')}
              className="p-1.5 rounded hover:bg-gray-100 text-gray-600"
              title="Italic"
            >
              <Italic className="h-4 w-4" />
            </button>
            <div className="w-px h-4 bg-gray-200 mx-2" />
            <button
              onClick={() => handleFormat('insertUnorderedList')}
              className="p-1.5 rounded hover:bg-gray-100 text-gray-600"
              title="Bullet List"
            >
              <List className="h-4 w-4" />
            </button>
            <button
              onClick={() => handleFormat('insertOrderedList')}
              className="p-1.5 rounded hover:bg-gray-100 text-gray-600"
              title="Numbered List"
            >
              <ListOrdered className="h-4 w-4" />
            </button>
            <div className="w-px h-4 bg-gray-200 mx-2" />
            <button
              onClick={() => {
                const url = prompt('Enter link URL:');
                if (url) handleFormat('createLink', url);
              }}
              className="p-1.5 rounded hover:bg-gray-100 text-gray-600"
              title="Insert Link"
            >
              <LinkIcon className="h-4 w-4" />
            </button>
            <label className="p-1.5 rounded hover:bg-gray-100 cursor-pointer text-gray-600">
              <ImageIcon className="h-4 w-4" />
              <input
                type="file"
                className="hidden"
                accept="image/*"
                onChange={handleInlineImageUpload}
              />
            </label>
            <button
              onClick={() => setShowEmbedModal(true)}
              className="p-1.5 rounded hover:bg-gray-100 text-gray-600"
              title="Embed Content"
            >
              <ExternalLink className="h-4 w-4" />
            </button>
          </div>

          {/* Category and Tags Selection */}
          <div className="flex items-center h-12 space-x-4 border-b border-gray-100">
            <div className="flex items-center space-x-2">
              <span className="text-sm text-gray-500">Category:</span>
              <button
                onClick={() => setShowCategoryModal(true)}
                className={`px-3 py-1 rounded-full text-sm ${
                  selectedCategory
                    ? 'bg-[#B6AC7D] text-white'
                    : 'bg-gray-100 text-gray-600'
                }`}
              >
                {selectedCategory ? categories.find(c => c.id === selectedCategory)?.name : 'Select Category'}
              </button>
            </div>
            <div className="flex-1 overflow-x-auto">
              <div className="flex items-center space-x-2">
                {selectedTags.map(tagId => {
                  const tag = tags.find(t => t.id === tagId);
                  return (
                    <span
                      key={tagId}
                      className="px-2 py-1 bg-gray-100 text-gray-600 rounded-full text-sm flex items-center"
                    >
                      {tag?.name}
                      <button
                        onClick={() => setSelectedTags(tags => tags.filter(t => t !== tagId))}
                        className="ml-1 text-gray-400 hover:text-gray-600"
                      >
                        <X className="h-3 w-3" />
                      </button>
                    </span>
                  );
                })}
              </div>
            </div>
          </div>

          {/* Status Bar */}
          <div className="h-14 flex items-center justify-between">
            <div className="flex items-center space-x-4 text-sm text-gray-500">
              <span className="flex items-center">
                <Clock className="h-4 w-4 mr-1" />
                {readingTime} min read
              </span>
              <span>{wordCount} words</span>
              <span className={`px-2 py-0.5 rounded-full text-xs ${
                isDraft 
                  ? 'bg-yellow-100 text-yellow-800' 
                  : 'bg-green-100 text-green-800'
              }`}>
                {isDraft ? 'Draft' : 'Published'}
              </span>
            </div>
            <div className="flex items-center space-x-3">
              {isSaving ? (
                <span className="text-[#B6AC7D]">Saving...</span>
              ) : lastSaved ? (
                <span className="text-gray-500">
                  Last saved {lastSaved.toLocaleTimeString()}
                </span>
              ) : null}
              <button
                onClick={() => handleSave(false)}
                disabled={isSaving}
                className="flex items-center px-4 py-2 text-[#B6AC7D] border border-[#B6AC7D] rounded-lg hover:bg-[#B6AC7D] hover:text-white transition-colors"
              >
                <Save className="h-4 w-4 mr-2" />
                Save Draft
              </button>
              <button
                onClick={() => handleSave(true)}
                disabled={isSaving}
                className="px-4 py-2 bg-[#B6AC7D] text-white rounded-lg hover:bg-[#A49C6D] transition-colors"
              >
                Publish
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Category Modal */}
      {showCategoryModal && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50 overflow-y-auto">
          <div className="bg-white rounded-lg w-full max-w-4xl max-h-[90vh] overflow-hidden">
            <div className="flex justify-between items-center p-6 border-b border-gray-200">
              <h3 className="text-xl font-semibold text-gray-900">Select Category & Tags</h3>
              <button
                onClick={() => setShowCategoryModal(false)}
                className="text-gray-400 hover:text-gray-500 transition-colors"
              >
                <X className="h-6 w-6" />
              </button>
            </div>

            <div className="p-6 overflow-y-auto max-h-[calc(90vh-8rem)]">
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                {/* Categories Section */}
                <div className="space-y-4">
                  <h4 className="font-medium text-gray-900 mb-4">Category</h4>
                  <div className="space-y-2 overflow-y-auto max-h-[50vh] lg:max-h-[60vh] pr-2">
                    {categories.map(category => (
                      <button
                        key={category.id}
                        onClick={() => setSelectedCategory(category.id)}
                        className={`w-full text-left p-4 rounded-lg transition-colors ${
                          selectedCategory === category.id
                            ? 'bg-[#B6AC7D] text-white'
                            : 'hover:bg-gray-50'
                        }`}
                      >
                        <div className="font-medium">{category.name}</div>
                        <div className="text-sm mt-1 opacity-80">{category.description}</div>
                      </button>
                    ))}
                  </div>
                </div>

                {/* Tags Section */}
                <div className="space-y-4">
                  <h4 className="font-medium text-gray-900 mb-4">Tags</h4>
                  <div className="overflow-y-auto max-h-[50vh] lg:max-h-[60vh] pr-2">
                    <div className="space-y-2">
                      {tags
                        .filter(tag => !selectedCategory || tag.category === selectedCategory)
                        .map(tag => (
                          <label
                            key={tag.id}
                            className="flex items-start p-3 hover:bg-gray-50 rounded-lg transition-colors cursor-pointer group"
                          >
                            <div className="flex items-center h-5">
                              <input
                                type="checkbox"
                                checked={selectedTags.includes(tag.id)}
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setSelectedTags([...selectedTags, tag.id]);
                                  } else {
                                    setSelectedTags(selectedTags.filter(t => t !== tag.id));
                                  }
                                }}
                                className="rounded border-gray-300 text-[#B6AC7D] focus:ring-[#B6AC7D] transition-colors"
                              />
                            </div>
                            <div className="ml-3">
                              <span className="text-sm font-medium text-gray-900 group-hover:text-[#B6AC7D] transition-colors">
                                {tag.name}
                              </span>
                            </div>
                          </label>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="p-6 border-t border-gray-200 bg-gray-50">
              <div className="flex flex-col sm:flex-row justify-end gap-3">
                <button
                  onClick={() => setShowCategoryModal(false)}
                  className="px-4 py-2 text-gray-700 hover:text-gray-900 transition-colors"
                >
                  Cancel
                </button>
                <button
                  onClick={() => setShowCategoryModal(false)}
                  className="px-6 py-2 bg-[#B6AC7D] text-white rounded-lg hover:bg-[#A49C6D] transition-colors"
                >
                  Done
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Embed Modal */}
      {showEmbedModal && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 w-full max-w-md">
            <h3 className="text-lg font-medium text-gray-900 mb-4">Embed Content</h3>
            <div className="space-y-4">
              <input
                type="text"
                value={embedUrl}
                onChange={(e) => setEmbedUrl(e.target.value)}
                placeholder="Enter URL to embed (YouTube, Twitter, etc.)"
                className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-[#B6AC7D] focus:border-transparent"
              />
              <div className="flex justify-end space-x-3">
                <button
                  onClick={() => setShowEmbedModal(false)}
                  className="px-4 py-2 text-gray-600 hover:text-gray-900"
                >
                  Cancel
                </button>
                <button
                  onClick={handleEmbed}
                  className="px-4 py-2 bg-[#B6AC7D] text-white rounded-lg hover:bg-[#A49C6D]"
                >
                  Embed
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default WritingEditor;