import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ArrowRight, TrendingUp } from 'lucide-react';
import { supabase } from '../lib/supabase';
import SupportSection from '../components/SupportSection';
import { marked } from 'marked';
import ArticleCard from '../components/ArticleCard';

const Home = () => {
  const [featuredArticles, setFeaturedArticles] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [videoError, setVideoError] = useState(false);
  const videoUrl = "https://nofaqhpljieekenyecze.supabase.co/storage/v1/object/public/public//CN%20Cover%20Video.mp4";

  const stripHtml = (html: string): string => {
    const tmp = document.createElement('div');
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || '';
  };

  useEffect(() => {
    const fetchFeaturedArticles = async () => {
      try {
        const { data: articles, error } = await supabase
          .rpc('get_featured_articles');

        if (error) throw error;

        const transformedArticles = articles?.map(article => {
          const htmlContent = marked(article.content || '');
          const plainText = stripHtml(htmlContent);
          const excerpt = plainText.substring(0, 120).trim() + '...';

          return {
            id: article.id,
            slug: article.slug,
            title: article.title,
            excerpt: excerpt,
            imageUrl: article.image_url,
            author: {
              name: article.author_username || 'Anonymous',
              avatar: article.author_avatar_url || 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e',
            },
            createdAt: article.created_at,
            stats: {
              viewCount: article.views_count || 0,
              likeCount: 0,
              commentCount: 0
            },
          };
        }) || [];

        setFeaturedArticles(transformedArticles);
      } catch (error) {
        console.error('Error fetching featured articles:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchFeaturedArticles();
  }, []);

  // Preload the video
  useEffect(() => {
    const preloadVideo = async () => {
      try {
        const videoElement = document.createElement('video');
        videoElement.muted = true;
        videoElement.crossOrigin = "anonymous";
        videoElement.preload = "auto";
        
        // Set up event handlers before setting src to avoid race conditions
        videoElement.oncanplaythrough = () => {
          setVideoLoaded(true);
        };
        
        videoElement.onerror = (e) => {
          console.error("Video failed to load", e);
          setVideoError(true);
        };
        
        // Now set the source
        videoElement.src = videoUrl;
        
        // Force the browser to start loading
        videoElement.load();
      } catch (err) {
        console.error("Error preloading video:", err);
        setVideoError(true);
      }
    };
    
    preloadVideo();
  }, []);

  return (
    <div className="min-h-screen bg-[#B6AC7D]">
      {/* Hero Section */}
      <section className="relative min-h-screen hero-section">
        <div className="absolute inset-0">
          <div className="absolute inset-0 bg-black/40 z-10" />
          {!videoError ? (
            <video
              autoPlay
              loop
              muted
              playsInline
              preload="auto"
              crossOrigin="anonymous"
              onLoadedData={() => setVideoLoaded(true)}
              onError={() => setVideoError(true)}
              className={`absolute w-full h-full object-cover transition-opacity duration-1000 ${
                videoLoaded ? 'opacity-100' : 'opacity-0'
              }`}
              poster="https://images.unsplash.com/photo-1504711434969-e33886168f5c?ixlib=rb-1.2.1&auto=format&fit=crop&w=2070&q=80"
            >
              <source
                src={videoUrl}
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          ) : (
            <img 
              src="https://images.unsplash.com/photo-1504711434969-e33886168f5c?ixlib=rb-1.2.1&auto=format&fit=crop&w=2070&q=80"
              alt="Mountains background"
              className="absolute w-full h-full object-cover"
            />
          )}
        </div>
        
        <div className="relative min-h-screen flex items-center z-20">
          <div className="w-full max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="max-w-4xl">
              <h1 className="text-7xl md:text-9xl font-bold text-white mb-8 animate-fade-up drop-shadow-lg font-serif">
                Stand on <br />
                The Truth
              </h1>
              <p className="text-xl text-white/90 mb-12 animate-fade-up drop-shadow-md max-w-2xl" style={{ animationDelay: '0.2s' }}>
                Experience journalism that illuminates the truth with unwavering clarity and purpose.
              </p>
              <div className="flex flex-wrap gap-4 animate-fade-up" style={{ animationDelay: '0.4s' }}>
                <Link
                  to="/category/all"
                  className="inline-flex items-center px-8 py-4 bg-[#B6AC7D] text-white rounded-full font-medium hover:bg-[#A49C6D] transition-colors shadow-lg hover:shadow-xl"
                >
                  Start Reading
                  <ArrowRight className="ml-2 h-5 w-5" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Featured Articles Section */}
      <section className="bg-white py-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-12">
            <h2 className="text-4xl font-bold text-gray-900 mb-4">Featured Stories</h2>
            <p className="text-xl text-gray-600">Our most-read and trending stories</p>
          </div>

          {loading ? (
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {[1, 2, 3].map((n) => (
                <div key={n} className="animate-pulse">
                  <div className="aspect-[4/3] bg-gray-200 rounded-xl mb-4" />
                  <div className="h-6 bg-gray-200 rounded w-3/4 mb-2" />
                  <div className="h-4 bg-gray-200 rounded w-1/2" />
                </div>
              ))}
            </div>
          ) : (
            <>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-12">
                {featuredArticles.map((article) => (
                  <ArticleCard key={article.id} article={article} featured={true} />
                ))}
              </div>

              <div className="text-center">
                <Link
                  to="/all-articles"
                  className="inline-flex items-center px-6 py-3 bg-[#B6AC7D] text-white rounded-lg hover:bg-[#A49C6D] transition-colors"
                >
                  View All Articles
                  <ArrowRight className="ml-2 h-5 w-5" />
                </Link>
              </div>
            </>
          )}
        </div>
      </section>

      {/* Support Section */}
      <SupportSection />
    </div>
  );
};

export default Home;