import React from 'react';
import { Shield, Users, Globe, Award, ArrowRight } from 'lucide-react';
import SupportSection from '../components/SupportSection';

const About = () => {
  return (
    <div className="min-h-screen bg-white pt-24">
      {/* Hero Section */}
      <section className="relative overflow-hidden bg-gradient-to-br from-[#B6AC7D]/10 via-white to-[#B6AC7D]/5 py-20">
        <div className="absolute inset-0">
          <div className="absolute inset-0 bg-[#B6AC7D] opacity-5 transform rotate-3 scale-150" />
        </div>
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h1 className="text-5xl font-bold text-gray-900 mb-6">Our Mission</h1>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              Clarity News stands as a beacon of truth in journalism, committed to delivering accurate, 
              unbiased reporting that illuminates the path forward for our readers.
            </p>
          </div>
        </div>
      </section>

      {/* Values Section */}
      <section className="py-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <h2 className="text-4xl font-bold text-gray-900 mb-4">Our Core Values</h2>
            <p className="text-xl text-gray-600">The principles that guide our journalism</p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            <div className="text-center">
              <div className="w-16 h-16 bg-[#B6AC7D]/10 rounded-full flex items-center justify-center mx-auto mb-6">
                <Shield className="h-8 w-8 text-[#B6AC7D]" />
              </div>
              <h3 className="text-xl font-bold text-gray-900 mb-4">Integrity</h3>
              <p className="text-gray-600">
                We uphold the highest standards of journalistic integrity in every story we publish.
              </p>
            </div>

            <div className="text-center">
              <div className="w-16 h-16 bg-[#B6AC7D]/10 rounded-full flex items-center justify-center mx-auto mb-6">
                <Users className="h-8 w-8 text-[#B6AC7D]" />
              </div>
              <h3 className="text-xl font-bold text-gray-900 mb-4">Community</h3>
              <p className="text-gray-600">
                Building connections and fostering meaningful dialogue within our community.
              </p>
            </div>

            <div className="text-center">
              <div className="w-16 h-16 bg-[#B6AC7D]/10 rounded-full flex items-center justify-center mx-auto mb-6">
                <Globe className="h-8 w-8 text-[#B6AC7D]" />
              </div>
              <h3 className="text-xl font-bold text-gray-900 mb-4">Global Perspective</h3>
              <p className="text-gray-600">
                Providing comprehensive coverage of events that shape our world.
              </p>
            </div>

            <div className="text-center">
              <div className="w-16 h-16 bg-[#B6AC7D]/10 rounded-full flex items-center justify-center mx-auto mb-6">
                <Award className="h-8 w-8 text-[#B6AC7D]" />
              </div>
              <h3 className="text-xl font-bold text-gray-900 mb-4">Excellence</h3>
              <p className="text-gray-600">
                Striving for excellence in every aspect of our journalism.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Team Section */}
      <section className="py-20 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <h2 className="text-4xl font-bold text-gray-900 mb-4">Our Team</h2>
            <p className="text-xl text-gray-600">Meet the people behind Clarity News</p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {/* Team Member 1 */}
            <div className="bg-white rounded-xl shadow-sm overflow-hidden">
              <img
                src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=600&h=400&q=80"
                alt="Team Member"
                className="w-full h-64 object-cover"
              />
              <div className="p-6">
                <h3 className="text-xl font-bold text-gray-900 mb-1">John Smith</h3>
                <p className="text-[#B6AC7D] font-medium mb-4">Editor in Chief</p>
                <p className="text-gray-600">
                  With over 20 years of experience in journalism, John leads our editorial team with 
                  vision and integrity.
                </p>
              </div>
            </div>

            {/* Team Member 2 */}
            <div className="bg-white rounded-xl shadow-sm overflow-hidden">
              <img
                src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=600&h=400&q=80"
                alt="Team Member"
                className="w-full h-64 object-cover"
              />
              <div className="p-6">
                <h3 className="text-xl font-bold text-gray-900 mb-1">Sarah Johnson</h3>
                <p className="text-[#B6AC7D] font-medium mb-4">Senior Reporter</p>
                <p className="text-gray-600">
                  Sarah specializes in investigative journalism, uncovering stories that matter to 
                  our community.
                </p>
              </div>
            </div>

            {/* Team Member 3 */}
            <div className="bg-white rounded-xl shadow-sm overflow-hidden">
              <img
                src="https://images.unsplash.com/photo-1519345182560-3f2917c472ef?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=600&h=400&q=80"
                alt="Team Member"
                className="w-full h-64 object-cover"
              />
              <div className="p-6">
                <h3 className="text-xl font-bold text-gray-900 mb-1">Michael Chen</h3>
                <p className="text-[#B6AC7D] font-medium mb-4">Technology Editor</p>
                <p className="text-gray-600">
                  Michael brings deep expertise in technology and its impact on society to our coverage.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Support Section */}
      <SupportSection />
    </div>
  );
};

export default About;