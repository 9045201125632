import { createClient } from '@supabase/supabase-js';

const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
const supabaseAnonKey = import.meta.env.VITE_SUPABASE_ANON_KEY;

if (!supabaseUrl || !supabaseAnonKey) {
  throw new Error('Missing Supabase environment variables');
}

// Create client with optimized settings
export const supabase = createClient(supabaseUrl, supabaseAnonKey, {
  auth: {
    autoRefreshToken: true,
    persistSession: true,
    detectSessionInUrl: false
  },
  global: {
    headers: {
      'x-application-name': 'clarity-news'
    }
  },
  db: {
    schema: 'public'
  },
  realtime: {
    params: {
      eventsPerSecond: 10
    }
  },
  // Add retry configuration
  fetch: (url, options = {}) => {
    const retryFetch = async (attempt = 1): Promise<Response> => {
      try {
        const response = await fetch(url, {
          ...options,
          headers: {
            ...options.headers,
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache'
          }
        });

        // Retry on 5xx errors or network failures
        if (!response.ok && response.status >= 500 && attempt < 3) {
          await new Promise(resolve => setTimeout(resolve, Math.pow(2, attempt) * 1000));
          return retryFetch(attempt + 1);
        }

        return response;
      } catch (error) {
        if (attempt < 3) {
          await new Promise(resolve => setTimeout(resolve, Math.pow(2, attempt) * 1000));
          return retryFetch(attempt + 1);
        }
        throw error;
      }
    };

    return retryFetch();
  }
});

// Add connection health check
export const checkSupabaseConnection = async () => {
  try {
    const { data, error } = await supabase.from('profiles').select('count').limit(1);
    if (error) throw error;
    return true;
  } catch (error) {
    console.error('Supabase connection error:', error);
    return false;
  }
};

// Add error tracking
export const logError = async (error: Error, context: any = {}) => {
  try {
    await supabase.from('error_logs').insert({
      error_message: error.message,
      error_stack: error.stack,
      context: context,
      created_at: new Date().toISOString()
    });
  } catch (e) {
    console.error('Failed to log error:', e);
  }
};

// Add retryable query function
export const retryableQuery = async <T>(
  queryFn: () => Promise<{ data: T | null; error: any }>,
  retries = 3
): Promise<T> => {
  for (let i = 0; i < retries; i++) {
    try {
      const { data, error } = await queryFn();
      
      if (error) {
        // If token expired, wait briefly and retry
        if (error.message === 'JWT expired') {
          await new Promise(resolve => setTimeout(resolve, 1000));
          continue;
        }
        throw error;
      }
      
      if (!data) {
        throw new Error('No data returned');
      }
      
      return data;
    } catch (error) {
      if (i === retries - 1) throw error;
      await new Promise(resolve => setTimeout(resolve, Math.pow(2, i) * 1000));
    }
  }
  
  throw new Error('Max retries exceeded');
};