import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Twitter, Facebook, Instagram, Rss, Send, CheckCircle2 } from 'lucide-react';
import { supabase } from '../lib/supabase';
import { useAuth } from '../contexts/AuthContext';

const Footer = () => {
  const { user, profile } = useAuth();
  const [email, setEmail] = useState('');
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleSubscribe = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    
    try {
      const { error: subscribeError } = await supabase
        .rpc('subscribe_to_newsletter', { subscriber_email: email });

      if (subscribeError) throw subscribeError;

      setIsSubscribed(true);
      setEmail('');
      setTimeout(() => setIsSubscribed(false), 3000);
    } catch (err) {
      console.error('Error subscribing to newsletter:', err);
      setError('Failed to subscribe. Please try again.');
      setTimeout(() => setError(null), 3000);
    }
  };

  return (
    <footer className="bg-white">
      {/* Newsletter Section */}
      <div className="relative overflow-hidden bg-gradient-to-br from-[#B6AC7D]/10 via-white to-[#B6AC7D]/5">
        <div className="absolute inset-0">
          <div className="absolute inset-0 bg-[#B6AC7D] opacity-5 transform rotate-3 scale-150" />
        </div>
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16 md:py-24">
          <div className="max-w-3xl mx-auto text-center">
            <h3 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">
              Stay Informed
            </h3>
            <p className="text-lg text-gray-600 mb-8">
              Join our community of truth-seekers. Get exclusive stories and updates delivered to your inbox.
            </p>
            <form onSubmit={handleSubscribe} className="relative max-w-lg mx-auto">
              <div className="flex flex-col sm:flex-row gap-4">
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter your email"
                  className="flex-1 px-6 py-4 rounded-full bg-white border border-gray-200 text-gray-900 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-[#B6AC7D] focus:border-transparent shadow-sm"
                  required
                />
                <button
                  type="submit"
                  className="inline-flex items-center justify-center px-8 py-4 bg-[#B6AC7D] text-white rounded-full font-medium hover:bg-[#A49C6D] transition-colors shadow-md hover:shadow-lg"
                >
                  Subscribe
                  <Send className="ml-2 h-5 w-5" />
                </button>
              </div>
              {isSubscribed && (
                <div className="absolute -bottom-8 left-0 right-0 text-center">
                  <span className="inline-flex items-center text-green-600">
                    <CheckCircle2 className="h-5 w-5 mr-2" />
                    Thank you for subscribing!
                  </span>
                </div>
              )}
              {error && (
                <div className="absolute -bottom-8 left-0 right-0 text-center">
                  <span className="text-red-600">{error}</span>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>

      {/* Main Footer Content */}
      <div className="border-t border-gray-100">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
          <div className="grid grid-cols-1 md:grid-cols-4 gap-12">
            {/* Brand */}
            <div className="space-y-6">
              <Link to="/" className="flex items-center space-x-2">
                <img
                  src="https://owkthkmwmioqetsacfth.supabase.co/storage/v1/object/public/logos/Clarity%20News%20Logo.png"
                  alt="CN Logo"
                  className="h-12 w-auto"
                />
              </Link>
              <p className="text-gray-500">
                Illuminating truth through journalism that matters. Standing firm on principles of accuracy, integrity, and clarity.
              </p>
              <div className="flex space-x-4">
                <a href="#" className="text-gray-400 hover:text-[#B6AC7D] transition-colors">
                  <Twitter className="h-5 w-5" />
                </a>
                <a href="#" className="text-gray-400 hover:text-[#B6AC7D] transition-colors">
                  <Facebook className="h-5 w-5" />
                </a>
                <a href="#" className="text-gray-400 hover:text-[#B6AC7D] transition-colors">
                  <Instagram className="h-5 w-5" />
                </a>
                <a href="#" className="text-gray-400 hover:text-[#B6AC7D] transition-colors">
                  <Rss className="h-5 w-5" />
                </a>
              </div>
            </div>

            {/* Quick Links */}
            <div>
              <h3 className="text-lg font-semibold text-gray-900 mb-6">Quick Links</h3>
              <ul className="space-y-4">
                <li>
                  <Link to="/" className="text-gray-500 hover:text-[#B6AC7D] transition-colors">
                    Home
                  </Link>
                </li>
                <li>
                  <Link to="/all-articles" className="text-gray-500 hover:text-[#B6AC7D] transition-colors">
                    All Articles
                  </Link>
                </li>
                <li>
                  <Link to="/about" className="text-gray-500 hover:text-[#B6AC7D] transition-colors">
                    About Us
                  </Link>
                </li>
                <li>
                  <Link to="/contact" className="text-gray-500 hover:text-[#B6AC7D] transition-colors">
                    Contact
                  </Link>
                </li>
                {user && (
                  <li>
                    <Link 
                      to={profile?.role === 'admin' ? '/admin' : '/writer'}
                      className="text-gray-500 hover:text-[#B6AC7D] transition-colors"
                    >
                      Dashboard
                    </Link>
                  </li>
                )}
              </ul>
            </div>

            {/* Categories */}
            <div>
              <h3 className="text-lg font-semibold text-gray-900 mb-6">Categories</h3>
              <ul className="space-y-4">
                <li>
                  <Link to="/category/politics" className="text-gray-500 hover:text-[#B6AC7D] transition-colors">
                    Politics
                  </Link>
                </li>
                <li>
                  <Link to="/category/technology" className="text-gray-500 hover:text-[#B6AC7D] transition-colors">
                    Technology
                  </Link>
                </li>
                <li>
                  <Link to="/category/health" className="text-gray-500 hover:text-[#B6AC7D] transition-colors">
                    Health
                  </Link>
                </li>
                <li>
                  <Link to="/category/science" className="text-gray-500 hover:text-[#B6AC7D] transition-colors">
                    Science
                  </Link>
                </li>
              </ul>
            </div>

            {/* Contact */}
            <div>
              <h3 className="text-lg font-semibold text-gray-900 mb-6">Contact Us</h3>
              <ul className="space-y-4 text-gray-500">
                <li>1234 Truth Street</li>
                <li>Clarity City, CL 12345</li>
                <li>contact@claritynews.com</li>
                <li>(555) 123-4567</li>
              </ul>
            </div>
          </div>

          {/* Bottom Bar */}
          <div className="border-t border-gray-100 mt-12 pt-8 text-center">
            <p className="text-gray-500 mb-2">&copy; {new Date().getFullYear()} Clarity News. All rights reserved.</p>
            <p className="text-gray-500">
              Website designed by{' '}
              <a 
                href="https://VISIONStudios.io" 
                target="_blank" 
                rel="noopener noreferrer"
                className="text-[#B6AC7D] hover:text-[#A49C6D] transition-colors"
              >
                VISION Studios
              </a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;