import React, { useState } from 'react';
import { X } from 'lucide-react';

const Banner = () => {
  const [isVisible, setIsVisible] = useState(true);

  if (!isVisible) return null;

  return (
    <div className="fixed top-24 left-0 right-0 z-40 bg-[#B6AC7D] text-white shadow-md">
      <div className="max-w-7xl mx-auto py-3 px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between flex-wrap gap-2">
          <div className="flex-1 flex items-center min-w-0">
            <p className="text-sm sm:text-base font-medium truncate">
              <span className="md:hidden">New update! Contact </span>
              <span className="hidden md:inline">Clarity News has a fresh new update! Please contact us at </span>
              <a href="mailto:hello@kingdomclarity.io" className="underline">hello@kingdomclarity.io</a>
              <span className="hidden md:inline"> if you experience any issues. We are continuing upgrades now, we hope you love the new site!</span>
            </p>
          </div>
          <div className="flex-shrink-0">
            <button
              type="button"
              onClick={() => setIsVisible(false)}
              className="flex p-2 hover:bg-[#A49C6D] rounded-md transition-colors"
            >
              <span className="sr-only">Dismiss</span>
              <X className="h-5 w-5" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;