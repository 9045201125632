import React, { useEffect } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './pages/Home';
import Article from './pages/Article';
import Admin from './pages/Admin';
import AdminSettings from './pages/AdminSettings';
import WriterDashboard from './pages/WriterDashboard';
import NewArticle from './pages/NewArticle';
import EditArticle from './pages/EditArticle';
import AllArticles from './pages/AllArticles';
import Latest from './pages/Latest';
import Auth from './pages/Auth';
import Account from './pages/Account';
import About from './pages/About';
import Contact from './pages/Contact';
import Category from './pages/Category';
import WriterProfile from './pages/WriterProfile';
import AuthModal from './components/AuthModal';
import ScrollToTop from './components/ScrollToTop';
import Banner from './components/Banner';
import { useAnalytics } from './hooks/useAnalytics';

function App() {
  // Track page views
  useAnalytics();
  const location = useLocation();

  // Add page transition class
  useEffect(() => {
    // Add page transition class
    document.body.classList.add('page-transition-enter');
    document.body.classList.add('page-transition-enter-active');
    
    const timeout = setTimeout(() => {
      document.body.classList.remove('page-transition-enter');
      document.body.classList.remove('page-transition-enter-active');
    }, 300);
    
    return () => {
      clearTimeout(timeout);
      document.body.classList.add('page-transition-exit');
      document.body.classList.add('page-transition-exit-active');
    };
  }, [location.pathname]);

  return (
    <div className="min-h-screen bg-white">
      <ScrollToTop />
      <Navbar />
      <Banner />
      <main>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/news/:slug" element={<Article />} />
          <Route path="/admin" element={<Admin />} />
          <Route path="/admin/settings" element={<AdminSettings />} />
          <Route path="/writer" element={<WriterDashboard />} />
          <Route path="/admin/new-article" element={<NewArticle />} />
          <Route path="/writer/new-article" element={<NewArticle />} />
          <Route path="/admin/edit/:id" element={<EditArticle />} />
          <Route path="/writer/edit/:id" element={<EditArticle />} />
          <Route path="/all-articles" element={<AllArticles />} />
          <Route path="/latest" element={<Latest />} />
          <Route path="/auth" element={<Auth />} />
          <Route path="/account" element={<Account />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/category/:slug" element={<Category />} />
          <Route path="/writer/:username" element={<WriterProfile />} />
          <Route path="/404" element={
            <div className="min-h-screen bg-gray-50 flex items-center justify-center">
              <div className="text-center">
                <h1 className="text-4xl font-bold text-gray-900 mb-4">Page Not Found</h1>
                <p className="text-gray-600 mb-8">The page you're looking for doesn't exist or has been moved.</p>
                <a href="/" className="text-[#B6AC7D] hover:text-[#A49C6D]">Return Home</a>
              </div>
            </div>
          } />
          <Route path="*" element={<Navigate to="/404" replace />} />
        </Routes>
      </main>
      <Footer />
      <AuthModal />
    </div>
  );
}

export default App;