import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { Heart, Star, Shield, Zap, CheckCircle2, ArrowRight, Upload } from 'lucide-react';

const SupportSection = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [showReceiptUpload, setShowReceiptUpload] = useState(false);
  const [receiptFile, setReceiptFile] = useState<File | null>(null);
  const [receiptNumber, setReceiptNumber] = useState('');

  const handleDonate = () => {
    // Direct to Stripe payment without requiring login
    window.location.href = 'https://buy.stripe.com/9AQ5kU1Rv8aw3kY5km';
  };

  const handleSubscribe = (plan: 'monthly' | 'yearly') => {
    // Require login for subscriptions
    if (!user) {
      navigate('/auth');
      return;
    }
    window.location.href = plan === 'monthly' 
      ? 'https://buy.stripe.com/aEU9Ba9jX62o08MeUX'
      : 'https://buy.stripe.com/5kAfZybs5bmIaNqbIM';
  };

  const handleReceiptSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    alert('Thank you! Your receipt has been submitted for verification.');
    setShowReceiptUpload(false);
    setReceiptFile(null);
    setReceiptNumber('');
  };

  return (
    <section className="relative py-20 overflow-hidden bg-white">
      <div className="absolute inset-0 bg-gradient-to-br from-[#B6AC7D]/10 to-transparent" />
      
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h2 className="text-4xl font-bold text-gray-900 mb-4">
            Support Our Mission
          </h2>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            Your support helps us to advance our mission to provide worldwide coverage for integrity-driven news and journalism, from an Eternal perspective. Equipping we the people to Stand on The Truth.
          </p>
        </div>

        <div className="grid md:grid-cols-2 gap-8 mb-16">
          {/* One-time Donation */}
          <div className="bg-white rounded-2xl shadow-xl overflow-hidden border border-gray-100 transform hover:scale-105 transition-transform duration-300">
            <div className="p-8">
              <div className="h-12 w-12 rounded-full bg-red-100 flex items-center justify-center mb-6">
                <Heart className="h-6 w-6 text-red-600" />
              </div>
              <h3 className="text-2xl font-bold text-gray-900 mb-4">
                Make a Donation
              </h3>
              <p className="text-gray-600 mb-6">
                Support our mission with a one-time donation. Every contribution helps us maintain our independence and continue delivering truth-focused journalism.
              </p>
              <ul className="space-y-3 mb-8">
                <li className="flex items-center text-gray-600">
                  <CheckCircle2 className="h-5 w-5 text-green-500 mr-3" />
                  Support independent journalism
                </li>
                <li className="flex items-center text-gray-600">
                  <CheckCircle2 className="h-5 w-5 text-green-500 mr-3" />
                  Help maintain our mission
                </li>
                <li className="flex items-center text-gray-600">
                  <CheckCircle2 className="h-5 w-5 text-green-500 mr-3" />
                  Enable truth-focused reporting
                </li>
              </ul>
              <button
                onClick={handleDonate}
                className="w-full flex items-center justify-center px-6 py-3 border border-transparent text-base font-medium rounded-lg text-white bg-red-600 hover:bg-red-700 transition-colors"
              >
                Donate Now
                <ArrowRight className="ml-2 h-5 w-5" />
              </button>
            </div>
          </div>

          {/* Premium Subscription */}
          <div className="bg-white rounded-2xl shadow-xl overflow-hidden border border-gray-100 transform hover:scale-105 transition-transform duration-300">
            <div className="p-8">
              <div className="h-12 w-12 rounded-full bg-[#B6AC7D]/20 flex items-center justify-center mb-6">
                <Star className="h-6 w-6 text-[#B6AC7D]" />
              </div>
              <h3 className="text-2xl font-bold text-gray-900 mb-4">
                Clarity News Premium
              </h3>
              <p className="text-gray-600 mb-6">
                Get exclusive access to in-depth analysis, early news releases, and an ad-free experience with our premium subscription.
              </p>
              <ul className="space-y-3 mb-8">
                <li className="flex items-center text-gray-600">
                  <Shield className="h-5 w-5 text-[#B6AC7D] mr-3" />
                  Ad-free experience
                </li>
                <li className="flex items-center text-gray-600">
                  <Zap className="h-5 w-5 text-[#B6AC7D] mr-3" />
                  Early access to articles
                </li>
                <li className="flex items-center text-gray-600">
                  <Star className="h-5 w-5 text-[#B6AC7D] mr-3" />
                  Exclusive content
                </li>
              </ul>
              <div className="space-y-3">
                <button
                  onClick={() => handleSubscribe('monthly')}
                  className="w-full flex items-center justify-center px-6 py-3 border border-transparent text-base font-medium rounded-lg text-white bg-[#B6AC7D] hover:bg-[#A49C6D] transition-colors"
                >
                  Monthly - $9.99/mo
                  <ArrowRight className="ml-2 h-5 w-5" />
                </button>
                <button
                  onClick={() => handleSubscribe('yearly')}
                  className="w-full flex items-center justify-center px-6 py-3 border border-[#B6AC7D] text-base font-medium rounded-lg text-[#B6AC7D] hover:bg-[#B6AC7D] hover:text-white transition-colors"
                >
                  Yearly - $99.99/yr (Save 17%)
                  <ArrowRight className="ml-2 h-5 w-5" />
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Receipt Upload Section */}
        <div className="text-center">
          <button
            onClick={() => setShowReceiptUpload(true)}
            className="inline-flex items-center text-[#B6AC7D] hover:text-[#A49C6D]"
          >
            <Upload className="h-5 w-5 mr-2" />
            Already subscribed? Upload your receipt
          </button>
        </div>

        {/* Receipt Upload Modal */}
        {showReceiptUpload && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
            <div className="bg-white rounded-lg max-w-md w-full p-6">
              <h3 className="text-xl font-bold text-gray-900 mb-4">
                Verify Your Purchase
              </h3>
              <form onSubmit={handleReceiptSubmit} className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Receipt Number
                  </label>
                  <input
                    type="text"
                    value={receiptNumber}
                    onChange={(e) => setReceiptNumber(e.target.value)}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-[#B6AC7D] focus:border-[#B6AC7D]"
                    placeholder="Enter your receipt number"
                    required
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Receipt Screenshot
                  </label>
                  <input
                    type="file"
                    onChange={(e) => setReceiptFile(e.target.files?.[0] || null)}
                    className="w-full"
                    accept="image/*"
                    required
                  />
                </div>
                <div className="flex justify-end space-x-3 mt-6">
                  <button
                    type="button"
                    onClick={() => setShowReceiptUpload(false)}
                    className="px-4 py-2 text-gray-600 hover:text-gray-900"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="px-4 py-2 bg-[#B6AC7D] text-white rounded-md hover:bg-[#A49C6D]"
                  >
                    Submit Receipt
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default SupportSection;