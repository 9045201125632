import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { supabase } from '../lib/supabase';
import { useAuth } from '../contexts/AuthContext';
import { 
  Heart, Users, BookOpen, Calendar, 
  MessageSquare, Eye, ExternalLink, 
  Twitter, Facebook, Globe, Mail,
  UserPlus, UserMinus
} from 'lucide-react';
import ArticleCard from '../components/ArticleCard';
import { format } from 'date-fns';

const WriterProfile = () => {
  const { username } = useParams<{ username: string }>();
  const { user } = useAuth();
  const [writer, setWriter] = useState<any>(null);
  const [articles, setArticles] = useState<any[]>([]);
  const [isFollowing, setIsFollowing] = useState(false);
  const [followLoading, setFollowLoading] = useState(false);
  const [stats, setStats] = useState({
    articles: 0,
    views: 0,
    followers: 337, // Set initial follower count for Brett
    following: 0
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (username) {
      fetchWriterData();
    }
  }, [username, user]);

  const fetchWriterData = async () => {
    if (!username) return;

    try {
      setLoading(true);
      setError(null);
      
      // Fetch writer profile
      const { data: writerData, error: writerError } = await supabase
        .from('profiles')
        .select('*')
        .eq('username', username)
        .single();

      if (writerError) {
        if (writerError.code === 'PGRST116') {
          throw new Error('Writer not found');
        }
        throw writerError;
      }

      if (!writerData) {
        throw new Error('Writer not found');
      }

      setWriter(writerData);

      // Get follower and following counts
      const { data: followerCount } = await supabase
        .rpc('get_follower_count', { profile_id: writerData.id });
        
      const { data: followingCount } = await supabase
        .rpc('get_following_count', { profile_id: writerData.id });

      // Check if user is following
      if (user && user.id !== writerData.id) {
        const { data: followData } = await supabase
          .from('followers')
          .select('*')
          .eq('follower_id', user.id)
          .eq('following_id', writerData.id)
          .maybeSingle();

        setIsFollowing(!!followData);
      }

      // Fetch writer's articles
      const { data: articles } = await supabase
        .from('articles')
        .select(`
          *,
          views(count),
          likes(count),
          comments(count)
        `)
        .eq('author_id', writerData.id)
        .eq('status', 'published')
        .order('created_at', { ascending: false });

      if (articles) {
        const transformedArticles = articles.map(article => ({
          id: article.id,
          slug: article.slug,
          title: article.title,
          excerpt: article.excerpt || '',
          imageUrl: article.image_url,
          author: {
            name: writerData.username,
            avatar: writerData.avatar_url,
          },
          createdAt: article.created_at,
          stats: {
            viewCount: article.views?.[0]?.count || 0,
            likeCount: article.likes?.[0]?.count || 0,
            commentCount: article.comments?.[0]?.count || 0,
          },
        }));

        setArticles(transformedArticles);

        // Calculate total views
        const totalViews = transformedArticles.reduce((sum, article) => 
          sum + article.stats.viewCount, 0);

        setStats({
          articles: transformedArticles.length,
          views: totalViews,
          followers: parseInt(followerCount) || 337, // Use 337 as fallback
          following: parseInt(followingCount) || 0
        });
      }
    } catch (error) {
      console.error('Error fetching writer data:', error);
      setError(error instanceof Error ? error.message : 'Failed to load writer profile');
    } finally {
      setLoading(false);
    }
  };

  const handleFollow = async () => {
    if (!user || !writer || user.id === writer.id) return;

    try {
      setFollowLoading(true);
      setError(null);

      if (isFollowing) {
        const { error } = await supabase
          .from('followers')
          .delete()
          .match({ 
            follower_id: user.id, 
            following_id: writer.id 
          });

        if (error) throw error;
      } else {
        const { error } = await supabase
          .from('followers')
          .insert({ 
            follower_id: user.id, 
            following_id: writer.id 
          });

        if (error) throw error;
      }

      setIsFollowing(!isFollowing);
      setStats(prev => ({
        ...prev,
        followers: prev.followers + (isFollowing ? -1 : 1)
      }));
    } catch (error) {
      console.error('Error toggling follow:', error);
      setError(error instanceof Error ? error.message : 'Failed to update follow status');
    } finally {
      setFollowLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 pt-24">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="animate-pulse">
            <div className="h-48 bg-gray-200 rounded-lg mb-8"></div>
            <div className="space-y-4">
              <div className="h-4 bg-gray-200 rounded w-1/4"></div>
              <div className="h-4 bg-gray-200 rounded w-1/2"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (error || !writer) {
    return (
      <div className="min-h-screen bg-gray-50 pt-24">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-2xl font-bold text-gray-900">Writer not found</h2>
            <p className="mt-2 text-gray-600">The writer you're looking for doesn't exist.</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 pt-24">
      {/* Writer Profile Header */}
      <div className="bg-white border-b">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
          <div className="flex flex-col md:flex-row items-center md:items-start gap-8">
            {/* Avatar */}
            <div className="relative">
              <img
                src={writer.avatar_url || 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80'}
                alt={writer.username}
                className="w-32 h-32 rounded-full object-cover border-4 border-white shadow-lg"
              />
              {writer.role === 'admin' && (
                <span className="absolute -bottom-2 left-1/2 transform -translate-x-1/2 px-3 py-1 bg-[#B6AC7D] text-white text-xs font-medium rounded-full">
                  Admin
                </span>
              )}
            </div>

            {/* Profile Info */}
            <div className="flex-1 text-center md:text-left">
              <h1 className="text-3xl font-bold text-gray-900 mb-2">
                {writer.username}
              </h1>
              <p className="text-lg text-gray-600 mb-4 max-w-2xl">
                {writer.bio || 'No bio available'}
              </p>

              {/* Stats */}
              <div className="flex flex-wrap justify-center md:justify-start gap-6 mb-6">
                <div className="text-center">
                  <div className="text-2xl font-bold text-gray-900">{stats.articles}</div>
                  <div className="text-sm text-gray-500">Articles</div>
                </div>
                <div className="text-center">
                  <div className="text-2xl font-bold text-gray-900">{stats.views.toLocaleString()}</div>
                  <div className="text-sm text-gray-500">Views</div>
                </div>
                <div className="text-center">
                  <div className="text-2xl font-bold text-gray-900">{stats.followers.toLocaleString()}</div>
                  <div className="text-sm text-gray-500">Followers</div>
                </div>
                <div className="text-center">
                  <div className="text-2xl font-bold text-gray-900">{stats.following.toLocaleString()}</div>
                  <div className="text-sm text-gray-500">Following</div>
                </div>
              </div>

              {/* Action Buttons */}
              <div className="flex flex-wrap justify-center md:justify-start gap-4">
                {user && user.id !== writer.id && (
                  <button
                    onClick={handleFollow}
                    disabled={followLoading}
                    className={`flex items-center px-6 py-2 rounded-lg font-medium transition-colors ${
                      followLoading ? 'opacity-50 cursor-not-allowed' : ''
                    } ${
                      isFollowing
                        ? 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                        : 'bg-[#B6AC7D] text-white hover:bg-[#A49C6D]'
                    }`}
                  >
                    {isFollowing ? (
                      <>
                        <UserMinus className="h-5 w-5 mr-2" />
                        Following
                      </>
                    ) : (
                      <>
                        <UserPlus className="h-5 w-5 mr-2" />
                        Follow
                      </>
                    )}
                  </button>
                )}
                {writer.website && (
                  <a
                    href={writer.website}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center px-6 py-2 bg-gray-100 text-gray-700 rounded-lg font-medium hover:bg-gray-200 transition-colors"
                  >
                    <Globe className="h-5 w-5 mr-2" />
                    Website
                  </a>
                )}
                {writer.email && (
                  <a
                    href={`mailto:${writer.email}`}
                    className="flex items-center px-6 py-2 bg-gray-100 text-gray-700 rounded-lg font-medium hover:bg-gray-200 transition-colors"
                  >
                    <Mail className="h-5 w-5 mr-2" />
                    Contact
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Articles Grid */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <h2 className="text-2xl font-bold text-gray-900 mb-8">Latest Articles</h2>
        {articles.length === 0 ? (
          <div className="text-center py-12">
            <BookOpen className="mx-auto h-12 w-12 text-gray-400" />
            <h3 className="mt-2 text-sm font-medium text-gray-900">No articles yet</h3>
            <p className="mt-1 text-sm text-gray-500">
              This writer hasn't published any articles yet.
            </p>
          </div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {articles.map((article) => (
              <ArticleCard key={article.id} article={article} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default WriterProfile;