import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { supabase } from '../lib/supabase';
import ArticleCard from '../components/ArticleCard';
import { Search, Filter } from 'lucide-react';
import { categories } from '../components/Navbar';
import { marked } from 'marked';

const Category = () => {
  const { slug } = useParams<{ slug: string }>();
  const navigate = useNavigate();
  const [articles, setArticles] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [sortBy, setSortBy] = useState('latest');

  const stripHtml = (html: string): string => {
    const tmp = document.createElement('div');
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || '';
  };

  useEffect(() => {
    fetchArticles();
  }, [slug, sortBy]);

  const fetchArticles = async () => {
    try {
      setLoading(true);
      setError(null);

      let query = supabase
        .from('articles')
        .select(`
          *,
          author:profiles(username, avatar_url),
          views(count),
          likes(count),
          comments(count)
        `)
        .eq('status', 'published');

      // Add category filter if not "all"
      if (slug !== 'all') {
        query = query.eq('category', slug);
      }

      // Add sorting
      if (sortBy === 'latest') {
        query = query.order('created_at', { ascending: false });
      } else if (sortBy === 'popular') {
        query = query.order('views_count', { ascending: false });
      }

      const { data: articles, error: fetchError } = await query;

      if (fetchError) throw fetchError;

      if (articles) {
        const transformedArticles = articles.map(article => {
          // Convert markdown to plain text for excerpt
          const htmlContent = marked(article.content || '');
          const plainText = stripHtml(htmlContent);
          const excerpt = plainText.substring(0, 100).trim() + '...';

          return {
            id: article.id,
            slug: article.slug,
            title: article.title,
            excerpt: excerpt,
            imageUrl: article.image_url,
            author: {
              name: article.author?.username || 'Anonymous',
              avatar: article.author?.avatar_url || 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e',
            },
            createdAt: article.created_at,
            stats: {
              viewCount: article.views?.[0]?.count || 0,
              likeCount: article.likes?.[0]?.count || 0,
              commentCount: article.comments?.[0]?.count || 0,
            },
          };
        });

        setArticles(transformedArticles);
      }
    } catch (error) {
      console.error('Error fetching articles:', error);
      setError('Error loading articles. Please try again.');
      navigate('/404');
    } finally {
      setLoading(false);
    }
  };

  const categoryInfo = categories.find(cat => cat.id === slug) || {
    name: 'All Articles',
    description: 'Explore our complete collection of articles.',
  };

  const getCategoryImage = () => {
    switch (slug) {
      case 'all':
        return 'https://images.unsplash.com/photo-1504711434969-e33886168f5c?ixlib=rb-1.2.1&auto=format&fit=crop&w=2070&q=80';
      case 'world-news':
        return 'https://images.unsplash.com/photo-1451187580459-43490279c0fa?ixlib=rb-1.2.1&auto=format&fit=crop&w=2070&q=80';
      case 'faith':
        return 'https://images.unsplash.com/photo-1501003878151-d3cb87799705?ixlib=rb-1.2.1&auto=format&fit=crop&w=2070&q=80';
      case 'lifestyle':
        return 'https://images.unsplash.com/photo-1511988617509-a57c8a288659?ixlib=rb-1.2.1&auto=format&fit=crop&w=2070&q=80';
      case 'business':
        return 'https://images.unsplash.com/photo-1486406146926-c627a92ad1ab?ixlib=rb-1.2.1&auto=format&fit=crop&w=2070&q=80';
      case 'entertainment':
        return 'https://images.unsplash.com/photo-1603190287605-e6ade32fa852?ixlib=rb-1.2.1&auto=format&fit=crop&w=2070&q=80';
      case 'sports':
        return 'https://images.unsplash.com/photo-1461896836934-ffe607ba8211?ixlib=rb-1.2.1&auto=format&fit=crop&w=2070&q=80';
      case 'tech':
        return 'https://images.unsplash.com/photo-1518770660439-4636190af475?ixlib=rb-1.2.1&auto=format&fit=crop&w=2070&q=80';
      case 'science':
        return 'https://images.unsplash.com/photo-1507413245164-6160d8298b31?ixlib=rb-1.2.1&auto=format&fit=crop&w=2070&q=80';
      case 'investigations':
        return 'https://images.unsplash.com/photo-1589829545856-d10d557cf95f?ixlib=rb-1.2.1&auto=format&fit=crop&w=2070&q=80';
      case 'voices':
        return 'https://images.unsplash.com/photo-1475721027785-f74eccf877e2?ixlib=rb-1.2.1&auto=format&fit=crop&w=2070&q=80';
      default:
        return 'https://images.unsplash.com/photo-1504711434969-e33886168f5c?ixlib=rb-1.2.1&auto=format&fit=crop&w=2070&q=80';
    }
  };

  const filteredArticles = articles.filter(article =>
    article.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
    article.excerpt.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="min-h-screen bg-white">
      {/* Category Hero */}
      <section className="hero-section relative h-[60vh]">
        <div className="absolute inset-0">
          <img
            src={getCategoryImage()}
            alt={categoryInfo.name}
            className="w-full h-full object-cover"
          />
          <div className="absolute inset-0 bg-black/50" />
        </div>
        <div className="relative h-full flex items-center pt-24">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
            <h1 className="text-5xl font-bold text-white mb-6">{categoryInfo.name}</h1>
            <p className="text-xl text-white/90 max-w-3xl mx-auto">
              {categoryInfo.description}
            </p>
          </div>
        </div>
      </section>

      {/* Filters */}
      <div className="border-b border-gray-200">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
          <div className="flex flex-col md:flex-row md:items-center justify-between gap-4">
            <div className="relative flex-1 max-w-lg">
              <input
                type="text"
                placeholder="Search articles..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="w-full pl-10 pr-4 py-2 rounded-lg border focus:ring-2 focus:ring-[#B6AC7D] focus:border-transparent"
              />
              <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
            </div>
            <div className="flex items-center space-x-4">
              <div className="flex items-center space-x-2">
                <Filter className="h-5 w-5 text-gray-400" />
                <select
                  value={sortBy}
                  onChange={(e) => setSortBy(e.target.value)}
                  className="pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-[#B6AC7D] focus:border-[#B6AC7D] rounded-lg"
                >
                  <option value="latest">Latest First</option>
                  <option value="popular">Most Popular</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Articles Grid */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        {loading ? (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {[1, 2, 3, 4, 5, 6].map((n) => (
              <div key={n} className="animate-pulse">
                <div className="bg-gray-200 h-64 rounded-lg mb-4"></div>
                <div className="h-4 bg-gray-200 rounded w-3/4 mb-2"></div>
                <div className="h-4 bg-gray-200 rounded w-1/2"></div>
              </div>
            ))}
          </div>
        ) : error ? (
          <div className="text-center py-12">
            <p className="text-red-600">{error}</p>
          </div>
        ) : filteredArticles.length === 0 ? (
          <div className="text-center py-12">
            <h3 className="text-lg font-medium text-gray-900 mb-2">No articles found</h3>
            <p className="text-gray-600">
              {searchQuery ? 'Try adjusting your search criteria' : 'No articles available in this category yet'}
            </p>
          </div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {filteredArticles.map((article) => (
              <ArticleCard key={article.id} article={article} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Category;