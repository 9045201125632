import React, { useState, useEffect, useRef } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { Heart, Eye, Share2, Copy, Check, Twitter, Facebook, Linkedin as LinkedIn, ArrowLeft } from 'lucide-react';
import { formatDistanceToNow } from 'date-fns';
import { supabase } from '../lib/supabase';
import CommentSection from '../components/CommentSection';
import { useAuth } from '../contexts/AuthContext';
import { marked } from 'marked';
import Dropdown from '../components/Dropdown';

const Article = () => {
  const { slug } = useParams();
  const { user } = useAuth();
  const navigate = useNavigate();
  const [article, setArticle] = useState<any>(null);
  const [isLiked, setIsLiked] = useState(false);
  const [likeCount, setLikeCount] = useState(0);
  const [comments, setComments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [showShareMenu, setShowShareMenu] = useState(false);
  const [copied, setCopied] = useState(false);
  const [readingProgress, setReadingProgress] = useState(0);
  const articleRef = useRef<HTMLElement>(null);
  const shareRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let mounted = true;

    const fetchData = async () => {
      if (!slug) return;
      
      try {
        setLoading(true);
        setError(null);

        // Get article data with retry logic
        const { data: article, error: articleError } = await supabase
          .from('articles')
          .select(`
            *,
            author:profiles(username, avatar_url, bio),
            likes(count),
            comments(count)
          `)
          .eq('slug', slug)
          .eq('status', 'published')
          .single();

        if (articleError) throw articleError;
        if (!article) throw new Error('Article not found');

        if (!mounted) return;

        setArticle(article);
        setLikeCount(article.likes?.[0]?.count || 0);

        // Increment view count asynchronously
        try {
          await supabase.rpc('increment_view', {
            article_slug: slug,
            user_id: user?.id || null,
            ip_address: null
          });
        } catch (viewError) {
          console.error('Error incrementing view:', viewError);
          // Don't throw here - we still want to show the article
        }

        // Check if user has liked
        if (user) {
          const { data: likes, error: likesError } = await supabase
            .from('likes')
            .select('id')
            .eq('article_id', article.id)
            .eq('user_id', user.id)
            .maybeSingle();

          if (!likesError && mounted) {
            setIsLiked(!!likes);
          }
        } else {
          // Check for guest like using localStorage
          const guestId = localStorage.getItem('guestId');
          if (guestId) {
            const { data: likes, error: likesError } = await supabase
              .from('likes')
              .select('id')
              .eq('article_id', article.id)
              .eq('guest_id', guestId)
              .maybeSingle();

            if (!likesError && mounted) {
              setIsLiked(!!likes);
            }
          }
        }

      } catch (error) {
        console.error('Error fetching article:', error);
        if (mounted) {
          setError(error instanceof Error ? error.message : 'Failed to load article. Please try again.');
        }
      } finally {
        if (mounted) {
          setLoading(false);
        }
      }
    };

    fetchData();

    return () => {
      mounted = false;
    };
  }, [slug, user]);

  // Handle reading progress
  useEffect(() => {
    const handleScroll = () => {
      if (!articleRef.current) return;
      
      const element = articleRef.current;
      const totalHeight = element.clientHeight;
      const windowHeight = window.innerHeight;
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      
      if (totalHeight - windowHeight <= 0) return;
      
      const scrolled = Math.min(100, Math.max(0, 
        (scrollTop / (totalHeight - windowHeight)) * 100
      ));
      
      setReadingProgress(scrolled);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [article]);

  // Handle click outside share menu
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (shareRef.current && !shareRef.current.contains(event.target as Node)) {
        setShowShareMenu(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleLike = async () => {
    if (!slug) return;

    try {
      const guestId = localStorage.getItem('guestId') || crypto.randomUUID();
      
      const { data: wasLiked, error } = await supabase.rpc('handle_article_like', {
        article_slug: slug,
        user_id: user?.id || null,
        guest_id: !user ? guestId : null,
        guest_ip: null // IP handled server-side
      });

      if (error) throw error;

      // Update UI state
      setIsLiked(wasLiked);
      setLikeCount(prev => wasLiked ? prev + 1 : prev - 1);

      // Store guest ID if needed
      if (!user) {
        localStorage.setItem('guestId', guestId);
      }
    } catch (error) {
      console.error('Error toggling like:', error);
      setError('Failed to update like. Please try again.');
      setTimeout(() => setError(null), 3000);
    }
  };

  const handleShare = async (platform?: string) => {
    const url = window.location.href;
    const title = article.title;

    if (platform === 'twitter') {
      window.open(`https://twitter.com/intent/tweet?text=${encodeURIComponent(title)}&url=${encodeURIComponent(url)}`);
    } else if (platform === 'facebook') {
      window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`);
    } else if (platform === 'linkedin') {
      window.open(`https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(url)}&title=${encodeURIComponent(title)}`);
    } else {
      try {
        await navigator.clipboard.writeText(url);
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      } catch (error) {
        console.error('Failed to copy URL:', error);
      }
    }
  };

  const renderContent = (content: string) => {
    if (article.slug === 'a-comprehensive-list-of-food-companies-and-products-that-use-senomyx') {
      return (
        <>
          <div className="prose max-w-none mb-8">
            <h1>A Comprehensive List of Food Companies and Products That Use Senomyx</h1>
            <p>A large number of major food and drink brands across The United States and the world have partnered with San Diego-based company "Senomyx" which has created a flavor enhancer using the cell lines of an electively aborted human being. I compiled a comprehensive list of the food brands using senomyx, for those who also seek to no longer support or consume these brands.</p>
            
            <h2>Introduction</h2>
            <p>I will go out of my way to not support companies who go out of their way to support the slaughter of innocent human children. You'll notice that there is almost always a more local, more organic option right beside the brand-name products, and these alternative options always seem to be many times healthier as well. They tend to lack ingredients like high-fructose corn syrup, red and blue dyes, along with lacking the use of senomyx as a flavor enhancer.</p>
          </div>

          <Dropdown title="Major Brands">
            <ul>
              <li>PepsiCo</li>
              <li>Nestle</li>
              <li>Kraft</li>
              <li>Campbell Foods</li>
              <li>Coca-Cola</li>
              <li>Solae</li>
              <li>PepsiCo (click here for a list of all their brands and products) - signed a four-year, $30 million agreement in 2010 for research and development (R&D) with Senomyx.</li>
              <li className="sub">Frito-Lay (click here for a list of brands and products)</li>
              <li className="sub">Tropicana (click here for a list of brands and products)</li>
              <li className="sub">Quaker</li>
              <li className="sub">Gatorade</li>
              <li className="sub">Lipton</li>
              <li>Kraft (here are two lists of Kraft brands and products: Kraft Foods website, and a separately compiled list. Note that Kraft is a parent company for Nabisco)</li>
              <li>Nestle (click here for a list of products and brands)</li>
              <li>Pfizer Inc (click here for a list of products)</li>
              <li>Merck & Co (click here for a list of products)</li>
            </ul>
          </Dropdown>

          <Dropdown title="Beverages (Water, Soda, Energy, Coffee, Tea, Milk)">
            <ul>
              <li>WATER</li>
              <li className="sub">Propel</li>
              <li className="sub">Aquafina</li>
              <li className="sub">Dasani (Coca-Cola)</li>
              <li className="sub">Pure Life</li>
              <li className="sub">Perrier</li>
              <li className="sub">S. Pellegrino</li>
              
              <li>SODA</li>
              <li className="sub">Pepsi (Pepsi)</li>
              <li className="sub">Sierra Mist</li>
              <li className="sub">Mountain Dew (Pepsi)</li>
              <li className="sub">Mug Root Beer (Pepsi)</li>
              <li className="sub">Barq's Root Beer (Coca-Cola)</li>
              <li className="sub">Sprite (Coca-Cola)</li>
              <li className="sub">Coca-Cola (Coca-Cola)</li>
              <li className="sub">Brisk (Pepsi)</li>
              <li className="sub">7 UP (Pepsi)</li>

              <li>ENERGY</li>
              <li className="sub">Gatorade (Pepsi)</li>
              <li className="sub">Powerade</li>
              <li className="sub">Vitamin Water</li>
              <li className="sub">SoBe</li>
              <li className="sub">Monster (Coca-Cola)</li>
              <li className="sub">AMP (Pepsi)</li>
              <li className="sub">Double Shot</li>

              <li>COFFEE</li>
              <li className="sub">Starbucks</li>
              <li className="sub">Seattle's Best Coffee</li>
              <li className="sub">Carnation Coffee-Mate</li>
              <li className="sub">Maxwell House Coffee</li>
              <li className="sub">Nescafe (Nestle)</li>
              <li className="sub">Taster's Choice</li>
              <li className="sub">Decaf</li>

              <li>TEA</li>
              <li className="sub">Nestea (Coca-Cola)</li>
              <li className="sub">Lipton Tea</li>
              <li className="sub">Honest Tea</li>
              <li className="sub">Sweet Leaf Tea</li>

              <li>JUICE</li>
              <li className="sub">Tropicana Products</li>
              <li className="sub">Ocean Spray Products</li>
              <li className="sub">Bolthouse Farms Products</li>
              <li className="sub">V8 Products</li>
              <li className="sub">Naked</li>
              <li className=" sub">Simply Orange</li>
              <li className="sub">Odwalla</li>
              <li className="sub">Fuze</li>
              <li className="sub">Kool-Aid</li>
              <li className="sub">CapriSun</li>
              <li className="sub">Juicy Juice</li>
              <li className="sub">Minute-Maid (Coca-Cola)</li>
              <li className="sub">Burn</li>
              <li className="sub">Dole</li>
              <li className="sub">Izzi</li>

              <li>MILK</li>
              <li className="sub">Nesquik</li>
            </ul>
          </Dropdown>

          <Dropdown title="Foods (Chips, Gum, Soups, Condiments, Medicines)">
            <ul>
              <li>CHIPS</li>
              <li className="sub">Frito Lay's (Pepsi)</li>
              <li className="sub">Sabritas</li>
              <li className="sub">Sun Chips (Pepsi)</li>
              <li className="sub">Doritos (Pepsi)</li>
              <li className="sub">Tostitos (Pepsi)</li>
              <li className="sub">Cheetos (Pepsi)</li>
              <li className="sub">Ruffles (Pepsi)</li>

              <li>GUM</li>
              <li className="sub">Trident</li>
              <li className="sub">Stride</li>
              <li className="sub">Dentyne</li>
              <li className="sub">Clorets</li>
              <li className="sub">Chiclets</li>
              <li className="sub">Bubblicious</li>

              <li>SOUPS</li>
              <li className="sub">Campbells Products</li>

              <li>CONDIMENTS</li>
              <li className="sub">Kraft Ketchup</li>
              <li className="sub">Kraft Mayonnaise</li>
              <li className="sub">A1 Steak Sauce</li>
              <li className="sub">Miracle Whip</li>
              <li className="sub">Cheese Whiz</li>

              <li>MEDICINES / Vitamins</li>
              <li className="sub">Halls Cough Drops</li>
              <li className="sub">Certs Cough Drops</li>
              <li className="sub">Boost</li>
              <li className="sub">Resource</li>
            </ul>
          </Dropdown>

          <Dropdown title="Candies">
            <ul>
              <li>Crunch</li>
              <li>Baby Ruth</li>
              <li>Butterfinger</li>
              <li>Aero</li>
              <li>Cailler</li>
              <li>Kit Kat</li>
              <li>Orion</li>
              <li>Smarties</li>
              <li>Wonka Products</li>
              <li>Nestle Products</li>
              <li>Cadbury Adams Products</li>
            </ul>
          </Dropdown>

          <Dropdown title="Pet Foods">
            <ul>
              <li>Alpo</li>
              <li>Baker's</li>
              <li>Benful</li>
              <li>Bloom</li>
              <li>Cat Chow</li>
              <li>Chef Michael's</li>
              <li>Dog Chow</li>
              <li>Fancy Feast</li>
              <li>Felix</li>
              <li>Friskies</li>
              <li>Gourmet</li>
              <li>Purina ONE</li>
              <li>Purina Pro Plan</li>
            </ul>
          </Dropdown>

          <Dropdown title="Direct Info. Sources">
            <ul>
              <li>Pepsi. https://cogforlife.org/2011/06/06/senomyx-and-pepsis-public-deception-all-the-proof-you-need/</li>
              <li>Senomyx patents and use of human cell lines. https://patft.uspto.gov/netacgi/nph-Parser?Sect1=PTO2&Sect2=HITOFF&u=/netahtml/PTO/search-adv.htm&r=0&p=1&f=S&l=50&Query=Senomyx&d=PTXT</li>
              <li>Kraft and Senomyx collaborative contract. https://contracts.onecle.com/senomyx/kraft.collab.2002.05.02.shtml</li>
              <li>Evidence of use of human taste receptors. https://www.ncbi.nlm.nih.gov/pmc/articles/PMC123709/</li>
            </ul>
            <p className="mt-4">An expanded list of direct sources will be added to this article shortly.</p>
          </Dropdown>
        </>
      );
    }

    // For other articles, render normally
    return (
      <div 
        className="prose max-w-none"
        dangerouslySetInnerHTML={{ 
          __html: marked(content || '') 
        }}
      />
    );
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-white flex items-center justify-center">
        <div className="w-16 h-16 border-4 border-[#B6AC7D] border-t-transparent rounded-full animate-spin"></div>
      </div>
    );
  }

  if (error || !article) {
    return (
      <div className="min-h-screen bg-white flex items-center justify-center">
        <div className="text-center">
          <h2 className="text-2xl font-bold text-gray-900 mb-4">Error Loading Article</h2>
          <p className="text-gray-600 mb-6">{error || 'Article not found'}</p>
          <Link
            to="/"
            className="px-4 py-2 bg-[#B6AC7D] text-white rounded-lg hover:bg-[#A49C6D]"
          >
            Return Home
          </Link>
        </div>
      </div>
    );
  }

  return (
    <article className="min-h-screen bg-white" ref={articleRef}>
      {error && (
        <div className="fixed top-20 left-1/2 transform -translate-x-1/2 bg-red-50 border border-red-200 text-red-600 px-4 py-3 rounded-lg z-50">
          {error}
        </div>
      )}

      {/* Reading Progress Bar */}
      <div 
        className="fixed top-0 left-0 z-50 h-1 bg-[#B6AC7D] transition-all duration-300"
        style={{ width: `${readingProgress}%` }}
      />

      {/* Back Button */}
      <button
        onClick={() => navigate(-1)}
        className="fixed top-24 left-4 z-40 flex items-center justify-center w-10 h-10 bg-white/80 backdrop-blur-sm rounded-full shadow-md hover:bg-white transition-colors duration-300 md:left-8"
        aria-label="Go back"
      >
        <ArrowLeft className="h-5 w-5 text-gray-700" />
      </button>

      {/* Article Header */}
      <header className="relative h-[60vh] overflow-hidden">
        <div className="absolute inset-0">
          <img
            src={article.image_url}
            alt={article.title}
            className="w-full h-full object-cover"
          />
          <div className="absolute inset-0 bg-gradient-to-t from-black/80 via-black/40 to-transparent" />
        </div>
        <div className="absolute inset-0 flex items-end">
          <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 pb-12 w-full">
            <h1 className="text-4xl md:text-5xl font-bold text-white mb-4">
              {article.title}
            </h1>
            <div className="flex items-center space-x-4 text-white/90">
              <img
                src={article.author.avatar_url}
                alt={article.author.username}
                className="w-10 h-10 rounded-full border-2 border-white/20"
              />
              <div>
                <div className="font-medium">{article.author.username}</div>
                <div className="text-sm">
                  {formatDistanceToNow(new Date(article.created_at), { addSuffix: true })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      {/* Article Content */}
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        {/* Article Actions */}
        <div className="sticky top-24 z-10 -ml-20 hidden lg:block">
          <div className="flex flex-col items-center space-y-4 bg-white/80 backdrop-blur-sm p-3 rounded-lg shadow-sm">
            <button
              onClick={handleLike}
              className={`p-2 rounded-full transition-colors ${
                isLiked ? 'text-[#B6AC7D] bg-[#B6AC7D]/10' : 'text-gray-500 hover:bg-gray-100'
              }`}
            >
              <Heart className={`h-6 w-6 ${isLiked ? 'fill-current' : ''}`} />
              <span className="sr-only">Like</span>
              <span className="text-xs mt-1">{likeCount}</span>
            </button>

            <div className="relative" ref={shareRef}>
              <button
                onClick={() => setShowShareMenu(!showShareMenu)}
                className="p-2 text-gray-500 hover:bg-gray-100 rounded-full transition-colors"
              >
                <Share2 className="h-6 w-6" />
                <span className="sr-only">Share</span>
              </button>

              {showShareMenu && (
                <div className="absolute left-full ml-2 bg-white rounded-lg shadow-lg py-2 min-w-[150px]">
                  <button
                    onClick={() => handleShare('twitter')}
                    className="w-full px-4 py-2 text-left text-gray-700 hover:bg-gray-100 flex items-center"
                  >
                    <Twitter className="h-4 w-4 mr-2" />
                    Twitter
                  </button>
                  <button
                    onClick={() => handleShare('facebook')}
                    className="w-full px-4 py-2 text-left text-gray-700 hover:bg-gray-100 flex items-center"
                  >
                    <Facebook className="h-4 w-4 mr-2" />
                    Facebook
                  </button>
                  <button
                    onClick={() => handleShare('linkedin')}
                    className="w-full px-4 py-2 text-left text-gray-700 hover:bg-gray-100 flex items-center"
                  >
                    <LinkedIn className="h-4 w-4 mr-2" />
                    LinkedIn
                  </button>
                  <button
                    onClick={() => handleShare()}
                    className="w-full px-4 py-2 text-left text-gray-700 hover:bg-gray-100 flex items-center"
                  >
                    {copied ? (
                      <Check className="h-4 w-4 mr-2 text-green-500" />
                    ) : (
                      <Copy className="h-4 w-4 mr-2" />
                    )}
                    {copied ? 'Copied!' : 'Copy Link'}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Article Body */}
        {renderContent(article.content)}

        {/* Mobile Article Actions */}
        <div className="flex items-center justify-center space-x-6 my-8 lg:hidden">
          <button
            onClick={handleLike}
            className={`flex flex-col items-center ${
              isLiked ? 'text-[#B6AC7D]' : 'text-gray-500'
            }`}
          >
            <Heart className={`h-6 w-6 ${isLiked ? 'fill-current' : ''}`} />
            <span className="text-xs mt-1">{likeCount}</span>
          </button>
          
          <button
            onClick={() => handleShare()}
            className="flex flex-col items-center text-gray-500"
          >
            <Share2 className="h-6 w-6" />
            <span className="text-xs mt-1">{copied ? 'Copied!' : 'Share'}</span>
          </button>
        </div>

        {/* Author Bio */}
        <div className="mt-12 border-t border-gray-200 pt-8">
          <div className="flex items-center space-x-4">
            <img
              src={article.author.avatar_url}
              alt={article.author.username}
              className="w-16 h-16 rounded-full"
            />
            <div>
              <h3 className="text-lg font-medium text-gray-900">
                {article.author.username}
              </h3>
              <p className="text-gray-600 mt-1">
                {article.author.bio || 'No bio available'}
              </p>
            </div>
          </div>
        </div>

        {/* Comments Section */}
        <div className="mt-12 border-t border-gray-200 pt-8">
          <h2 className="text-2xl font-bold text-gray-900 mb-8">Comments</h2>
          <CommentSection
            comments={comments}
            onAddComment={async () => {}}
            onLikeComment={async () => {}}
          />
        </div>
      </div>
    </article>
  );
};

export default Article;